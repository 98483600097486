<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">学法活动</el-breadcrumb-item>
					<el-breadcrumb-item>演讲比赛</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br>
		<div class="main_w mar_auto" style="min-height: 600px;">
			<div class="flex_start">
				<div class="m-r-10 m-b-10">
					<el-tag class="com_pointer tag_css" :effect="group.pid==0?'dark':'plain'"
						@click="groupSelectFN(1,0)">全部</el-tag>
				</div>
				<div class="m-r-10 m-b-10" v-for="(item,index) in group.list" :key="index">
					<el-tag class="com_pointer tag_css" :effect="group.pid==item.id?'dark':'plain'"
						@click="groupSelectFN(1,item.id)">{{item.name}}</el-tag>
				</div>
			</div>
			<div class="flex_start m-t-10">
				<div class="m-r-10 m-b-10" v-for="(item,index) in group.list2" :key="index">
					<el-tag class="com_pointer tag_css" :effect="group.pid2==item.id?'light':'plain'"
						@click="groupSelectFN(2,item.id)">{{item.name}}</el-tag>
				</div>
			</div>
			<div>
				<div v-for="(item,index) in list" :key="index">
					<router-link :to="'/matchSpeechDetail?id=' +item.id" target="_blank">
						<div class="news_item">
							<div class="flex_between">
								<div class="flex_align">
									<div class="m-r-30" v-if="item.cover"><img :src="item.cover" alt=""
											style="width:200px;height:133px"></div>
									<div>
										<div class="com_ellipsis2 font_s18 font_weight">{{item.name}}</div>
										<div class="com_ellipsis2 m-t-10">{{item.des}}</div>
										<div class="m-t-10">报名时间：{{item.join_date}}</div>
										<div class="m-t-10">活动时间：{{item.activity_date}}</div>
										<!-- <div class="">
										<img src="@/assets/img/right.png" style="width:25px;height:15px" alt="">
									</div> -->
									</div>
								</div>
								<div>
									<el-tag color="default" v-if="item.activity_status==0">未开始</el-tag>
									<el-tag color="processing" v-if="item.activity_status==1">进行中</el-tag>
									<el-tag color="default" v-if="item.activity_status==2">已结束</el-tag>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c main_w mar_auto" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:total="page.total"></el-pagination>
		</div>
		<br><br><br>
	</div>
</template>

<script>
	export default {
		name: 'MatchSpeechIndex',
		components: {},
		props: [''],
		data() {
			return {
				keywords: "",
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
				},
				group: {
					groupids: "",
					pid: 0,
					pid2: 0,
					list: [],
					list2: [],
				}
			};
		},
		computed: {},
		beforeMount() {},
		activated() {},
		mounted() {
			this.getGroupItemFN(1, 0);
			this.getActivityItemFN();
		},
		methods: {
			//触发搜索
			searchListenFN() {
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getActivityItemFN();
			},
			//切换分页
			changePageFN(page) {
				this.getActivityItemFN(page);
			},
			//获取文章列表
			getActivityItemFN(page) {
				const params = {
					page: page ? page : 1,
					name: this.keywords,
					groupids: this.groupids
				}
				this.$api.activity.getItem(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//选择组别
			groupSelectFN(type, id) {
				if (type == 1) {
					this.group.pid = id;
					if (id == 0) {
						this.group.list2 = [];
						this.getGroupItemFN(1)
					} else {
						this.getGroupItemFN(2);
					}
				} else {
					this.group.pid2 = id;
				}
				if (id != 0) {
					this.groupids = id;
				} else {
					this.groupids = "";
				}
				this.getActivityItemFN()
			},
			//获取分组列表
			getGroupItemFN(type) {
				const params = {
					pid: this.group.pid
				}
				this.$api.group.getItem(params).then((res) => {
					// 处理相应的业务
					if (type == 1) {
						this.group.list = res.data.list;
					} else {
						this.group.list2 = res.data.list;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		border-bottom: 1px solid #EAEAEA;
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		padding-right: 14px;
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
	}

	.tag_css {
		min-width: 100px;
		text-align: center;
	}
</style>