<template>
	<div class="com_position">
		<div class="nav_header flex_jc">
			<img src="@/assets/img/header_bg.png"
				style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 0;" alt="">
			<div class="main_w com_position">
				<br><br>
				<div class="max_h flex_between m-b-10">
					<div class="flex_jc max_h">
						<img src="@/assets/img/logo.png" class="com_pointer" @click="toRouter('/')"
							style="height: 57px;" alt="" />
					</div>
					<div class="flex_align max_h" style="max-width: 90%;">
						<div class="search_box flex_align">
							<i class="el-icon-search"></i>
							<el-input v-model="search" class="search" @keyup.enter.native="onSearch"
								placeholder="搜索你感兴趣的"></el-input>
							<el-button class="flex_jc" type="primary"
								style="background-color: #163F88;border: 1px solid #163F88;height: 100%;font-weight: 400;border-radius: 8px;width: 100px;"
								@click="onSearch()">搜索</el-button>
						</div>
					</div>
				</div>
				<div style="position: relative;top: 0;">
					<el-row class="max_h" type="flex" justify="space-between">
						<el-col :span="24" class="" style="margin-top: 20px;">
							<el-menu :default-active="activeIndex" mode="horizontal" class="flex_between"
								background-color="#163F88" text-color="#fff" active-text-color="#fff">
								<el-menu-item class="" index="/" @click="toRouter('/')">
									首页
								</el-menu-item>
								<el-menu-item index="/news" @click="toRouter('/news')">
									新闻动态
								</el-menu-item>
								<el-submenu index="/matchKnowledgeIndex">
									<template slot="title">学法活动</template>
									<el-menu-item index="/matchSpeechIndex" class=""
										@click="toRouter('/matchSpeechIndex')">
										演讲比赛
									</el-menu-item>
									<el-menu-item index="/matchKnowledgeIndex" class=""
										@click="toRouter('/matchKnowledgeIndex')">法治素养竞赛</el-menu-item>
									<el-menu-item index="/studyLaw" @click="$message('敬请期待！')">法治学习</el-menu-item>
								</el-submenu>
								<el-submenu index="/law">
									<template slot="title">法律知识</template>
									<el-menu-item @click="toRouter('/law',item.id)" v-for="(item,index) in lawMenu"
										:index="'/law?cateids='+item.id" :key="index">{{item.name}}</el-menu-item>
								</el-submenu>
								<el-menu-item index="/lawAdvice" @click="toLink(Law.link)">
									法律咨询
								</el-menu-item>
								<el-submenu index="/study">
									<template slot="title">学习资料</template>
									<el-menu-item @click="toRouter('/study',item.id)" v-for="(item,index) in studyMenu"
										:index="'/study?id='+item.id" :key="index">{{item.name}}</el-menu-item>
								</el-submenu>
								<el-menu-item index="/news-team" @click="toRouter('/news-team/index')">
									专家队伍
								</el-menu-item>
								<!-- <el-menu-item index="9"  @click="toLink(Shop.link)">
								商城
							</el-menu-item> -->
								<el-menu-item class="" v-if="u_token" index="/center" @click="toRouter('/center')">
									个人中心
								</el-menu-item>
								<el-menu-item v-if="!u_token" index="/login" @click="toRouter('/login')">
									登录/注册
								</el-menu-item>
							</el-menu>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "NavHeader",
		components: {},
		data() {
			return {
				activeIndex: "/",
				Shop: {
					link: "",
				},
				Law: {
					link: "",
				},
				lawMenu: [],
				studyMenu: [],
				search: "",
				u_token: localStorage.getItem('u_token'),
			};
		},
		watch: {
			$route() {
				this.setCurrentRoute();
			},
		},
		created() {
			this.setCurrentRoute();
		},
		mounted() {
			// this.getDataFN('Shop');
			this.getDataFN('Law');
			this.getLawCateFN();
			this.getStudyCateFN();
		},
		methods: {
			//导航栏菜单刷新后根据路径回显
			setCurrentRoute() {
				var path = this.$route.path;
				if (path === '/matchSpeechIndex' || path === '/matchKnowledgeDetail') {
					this.activeIndex = '/matchKnowledgeIndex';
				} else if (path === '/study') {
					this.activeIndex = '/study?id=' + this.$route.query.cateids;
				} else if (path === '/register' || path === '/forgot') {
					this.activeIndex = '/login';
				} else if (path === '/law') {
					this.activeIndex = '/law?cateids=' + this.$route.query.cateids;
				} else if (path === '/law_detail') {
					this.activeIndex = '/law?id=' + this.$route.query.id;
				} else if (path === '/news_detail') {
					this.activeIndex = '/news';
				} else if (path === '/news-team/detail') {
					this.activeIndex = '/news-team';
				} else if (path === '/base/law' || path === '/base/about' || path === '/base/contact') {
					this.activeIndex = '';
				} else {
					this.activeIndex = this.$route.path // 通过他就可以监听到当前路由状态并激活当前菜单
				}
			},
			//获取法律知识资料菜单分类
			getLawCateFN() {
				// 开启登录按钮的 loading
				this.$api.law.getCate({}).then((res) => {
					// 处理相应的业务
					this.lawMenu = res.data;
				}).catch((error) => {
					// catch 这一步已经在拦截器中统一处理了,所以实际中不用写catch，特殊情况例外。
					console.log(error)
				}).finally(() => {
					// 关闭loading
				})
			},
			//获取学习资料菜单分类
			getStudyCateFN() {
				// 开启登录按钮的 loading
				this.$api.study.getCate({}).then((res) => {
					// 处理相应的业务
					this.studyMenu = res.data;
				}).catch((error) => {
					// catch 这一步已经在拦截器中统一处理了,所以实际中不用写catch，特殊情况例外。
					console.log(error)
				}).finally(() => {
					// 关闭loading
				})
			},
			//导航栏url
			getDataFN(name) {
				const params = {
					name: name
				}
				// 开启登录按钮的 loading
				this.$api.data.getData(params).then((res) => {
					// 处理相应的业务
					this.$data[name] = res.data;
				}).catch((error) => {
					// catch 这一步已经在拦截器中统一处理了,所以实际中不用写catch，特殊情况例外。
					console.log(error)
				}).finally(() => {
					// 关闭loading
				})
			},
			//路由跳转
			toRouter(name, cateids) {
				this.search = '';
				if (name == '/center') {
					if (!localStorage.getItem('u_token')) {
						this.$message.info('请先登录！');
						name = '/login';
					}
				}
				this.$router.push({
					path: name,
					// target: '_blank',
					query: {
						cateids: cateids
					}
				});
				// let {
				// 	href
				// } = this.$router.resolve({
				// 	path: name,
				// 	target: '_blank',
				// 	query: {
				// 		cateids: cateids
				// 	}
				// });
				// window.open(href, "_blank")
			},
			toLink(url) {
				if (!url) {
					this.$message.info('敬请期待');
					return;
				}
				window.open(url, "_blank")
			},
			onSearch() {
				if (this.$route.name === 'home') {
					this.$router.push({
						path: '/news',
						query: {
							keywords: this.search,
						}
					});
				} else {
					this.$emit('searchNav', this.search);
				}
			}
		}
	};
</script>
<style scoped>
	a {
		color: white !important;
	}
</style>
<style lang="less">
	.search_box {
		height: 40px;
		padding: 3px 3px 3px 8px;
		width: 100%;
		background: #FFFFFF;
		border-radius: 8px;

		.el-input__inner {
			border: 0 !important;
			border-bottom: 0 !important;
		}
	}


	.nav_header {
		width: 100%;
		height: 160px;
		margin-bottom: 40px;
		background: #3568C7;
		font-weight: 600;
		font-family: STSongti-SC-Black, STSongti-SC;
		font-size: 16px;

		.el-submenu__title i {
			color: white;
		}

		.el-menu {
			overflow: hidden;
			border-radius: 8px;
		}

		.el-menu,
		.el-submenu,
		.el-submenu__title,
		.el-menu-item {
			font-size: 15px !important;
			/* height: 100px !important;
			line-height: 100px !important; */
		}

		.el-menu-item {
			text-align: center;
			width: 100%;
			background-color: transparent;
		}

		.el-menu-item.is-active {
			border-bottom-color: #2276D5 !important;
			background-color: #2276D5 !important;
		}

		.el-menu.el-menu--horizontal {
			border: none;
		}

		//一级
		.el-submenu__title:hover,
		.el-menu-item:focus,
		.el-menu-item:hover {
			background-color: #2276D5 !important;
		}

	}

	.el-menu--horizontal {
		box-shadow: inset 0px 6px 0px 0px #3065C4;
	}

	//二级菜单
	.el-menu--popup {
		// padding: 0;
		border-radius: 0px 0px 8px 8px;
		// border-radius: 0!important;
		background-color: white !important;
		overflow: hidden;

		.el-menu-item {
			font-size: 15px;
			border-radius: 0px;
			text-align: center;
			color: #000 !important;
			background-color: white !important;
		}

		.el-menu-item:hover {
			color: #2276D5 !important;
			// background-color: #2276D5 !important;
		}

		.el-menu-item.is-active {
			color: #2276D5 !important;
			// background-color: #F0F0F0 !important;
		}
	}
</style>