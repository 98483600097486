<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">学法活动</el-breadcrumb-item>
					<el-breadcrumb-item class="color_A6A6A6" @click="$router.back()">演讲比赛</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br><br>
		<div class="main_w mar_auto">
			<div class="flex_jc">
				<div class="rectangle_icon bgcolor_main"></div>
				<div class="m-l-10 f-24 font_weight fel-s">{{info.name}}</div>
			</div>
			<div class="p-l-10 p-b-10 border_bottom m-t-13"></div>
			<div class="m-t-10 flex_between">
				<div>
					<div>报名时间：{{info.join_date}}</div>
					<div class="m-t-5">活动时间：{{info.activity_date}}</div>
					<div class="flex_align m-t-5">
						<div>活动状态：</div>
						<el-tag color="success" v-if="info.activity_status==0">未开始</el-tag>
						<el-tag color="processing" v-if="info.activity_status==1">进行中</el-tag>
						<el-tag color="default" v-if="info.activity_status==2">已结束</el-tag>
					</div>
				</div>
				<div class="">
					<div class="m-b-5" v-if="info.join_status==1">
						<el-button type="primary" v-if="!activity_user.id && user_info.u_type==1"
							@click="drawerOpenFN()">马上报名</el-button>
						<el-button type="primary" v-if="user_info.u_type!=1" @click="drawerOpenFN()">添加报名信息</el-button>
					</div>
					<div class="flex_jc" v-if="activity_user.status>1">
						<el-button type="danger" style="width: 200px;" @click="toUserCenter()">查看我的演讲比赛</el-button>
					</div>
				</div>
			</div>
			<br><br>
			<div v-if="showBatchData">
				<!-- 批量表格信息 -->
				<div>
					<div class="text_c">
						<el-upload class="" :on-change="changeFileUser" :on-success="successFileUser"
							:on-error="errorFileUser" :before-upload="beforeUpload" :on-remove="removeFileUser"
							:on-exceed="exceedFileUser" :file-list="file_user_list" drag :action="action_url"
							:multiple="false" :headers="{'api-name':'web','api-token':u_token}">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								<div>将《比赛成员信息汇总表》文件拖到此处，或<em>点击上传</em></div>
								<div class="el-upload__tip color_A6A6A6" slot="tip">建议上传压缩包zip，大小不高于600M的文件</div>
							</div>
						</el-upload>
					</div>
					<br><br>
					<div>
						<el-table :data="batchUser" style="width: 100%" border>
							<el-table-column type="index" label="序号" align="center" width="180">
							</el-table-column>
							<el-table-column prop="username" label="报名-姓名" align="center" width="180">
							</el-table-column>
							<el-table-column prop="phone" label="报名-手机号码" align="center" width="180">
							</el-table-column>
							<el-table-column prop="idcode" label="报名-身份证号" align="center">
							</el-table-column>
							<el-table-column prop="sex" label="报名-性别" align="center">
							</el-table-column>
							<el-table-column prop="file" label="报名-作品文件" align="center">
								<template slot-scope="scope">
									<a style="color: #4A90E2!important;" target="_blank" :href="scope.row.file"
										:download="scope.row.username+'-作品文件'" v-if="scope.row.file">已上传</a>
								</template>
							</el-table-column>
							<el-table-column align="center">
								<template slot-scope="scope">
									<el-button size="mini" @click="batchUserEdit(scope.$index, scope.row)">编
										辑</el-button>
									<el-button size="mini" type="danger"
										@click="batchUserDelete(scope.$index, scope.row)">删
										除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<br><br>
					<div class="text_c">
						<el-button @click="cancelManyFN()">取消报名</el-button>
						<el-button type="success" @click="postManyFN()" v-if="user_info.u_type!=1">提交报名信息</el-button>
					</div>
					<br> <br>
				</div>
				<br>
			</div>
			<div class="" v-show="showBatchData==false">
				<!-- 内容 -->
				<div class="" v-html="info.content"></div>
			</div>
		</div>
		<!-- 活动报名 -->
		<el-dialog title="填写报名信息" :visible.sync="visible" width="40%" :close-on-press-escape="false"
			:close-on-click-modal="false">
			<div class="mar_auto" style="width: 80%;">
				<el-form :model="formState" :label-col="{ span: 5 }" :wrapper-col="{ span: 4 }" autocomplete="off">
					<el-form-item label="报名-姓名" required>
						<el-input v-model="formState.username" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="报名-手机号码" required>
						<el-input v-model="formState.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="报名-身份证号" :required="true">
						<el-input v-model="formState.idcode" placeholder="请输入身份证号"></el-input>
					</el-form-item>
					<el-form-item label="报名-性别" has-feedback required>
						<el-select v-model="formState.sex" placeholder="请选择您的性别" class="max_w">
							<el-option v-for="item in sex_options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<br>
					<div class="text_c">
						<el-upload class="" :on-change="changeFile" :on-success="successFile" :on-remove="removeFile"
							:on-error="errorFile" :before-upload="beforeUpload" :on-exceed="exceedFile" drag
							:file-list="file_list" :action="action_url" :multiple="false"
							:headers="{'api-name':'web','api-token':u_token}">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								<div>将作品文件拖到此处，或<em>点击上传</em></div>
								<div class="el-upload__tip color_A6A6A6" slot="tip">请直接上传视频，大小不高于600M的文件</div>
							</div>
						</el-upload>
					</div>
					<br>
					<el-form-item class="text_c">
						<el-button @click="drawerCloseFN()">取&nbsp;消</el-button>
						<el-button type="primary" @click="postCheckFN()">确&nbsp;认</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<br><br>
	</div>
</template>

<script>
	export default {
		name: 'MatchSpeechDetail',
		components: {},
		props: [''],
		data() {
			return {
				u_token: localStorage.getItem('u_token'),
				action_url: '',
				id: "",
				info: {},
				visible: false,
				file_user: "",
				formState_edit_index: -1,
				formState: {
					username: "",
					phone: "",
					idcode: "",
					sex: "男",
					file: "",
				},
				showBatchData: false,
				batchUser: [],
				sex_options: [{
					value: '男',
					label: '男'
				}, {
					value: '女',
					label: '女'
				}],
				activity_user: {},
				user_info: {},
				file_list: [], //编辑时显示
				file_user_list: []
			}
		},
		created() {
			this.action_url = this.$api.baseUrl + '/api/auth.center/upload';
		},
		computed: {},
		beforeMount() {},
		activated() {
			if (!this.$route.query.id) {
				return;
			}
			this.id = this.$route.query.id;
			this.getActivityItemFN()
		},
		methods: {
			//取消报名信息
			cancelManyFN() {
				this.$confirm('确认要取消报名，取消后将清空当前已编辑的数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.showBatchData = false;
					this.batchUser = [];
				}).catch(() => {});
			},
			//获取个人信息
			getUserInfo() {
				const params = {

				}
				this.$api.auth.center.get(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.user_info = res.data;
						if (this.user_info.u_type == 1) {
							this.formState.username = this.user_info.username;
							this.formState.idcode = this.user_info.idcode;
						}
					}
					// this.$forceUpdate();
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//查看我的赛事参与
			toUserCenter() {
				this.$router.push({
					path: 'center',
					query: {
						tab: 'userActivity'
					}
				})
			},
			//表格-编辑
			batchUserEdit(index, row) {
				this.formState_edit_index = index;
				this.formState = row;
				if (this.formState.file) {
					this.file_list = [{
						'name': "文件已上传",
						"url": this.formState.file
					}]
				} else {
					this.file_list = [];
				}
				this.drawerOpenFN();
			},
			//表格-删除
			batchUserDelete(index) {
				this.batchUser.splice(index, 1)
			},
			//比赛成员信息汇总表文件上传成功回调
			successFileUser(res) {
				this.$message.success('文件上传成功');
				this.file_user = res.data.url;
			},
			errorFileUser() {
				this.$message.error('当前网络异常，请检查网络，重新上传');
			},
			//文件超出个数限制时的钩子	
			exceedFileUser() {
				this.$message.warning('超出1个文件，请先删除当前文件，再重新上传');
			},
			//删除比赛信息表
			removeFileUser() {
				this.file_user = '';
			},
			//文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用	
			changeFileUser(files, fileList) {
				if (fileList.length > 0) {
					this.file_user_list = [files];
				}
			},
			beforeUpload(file) {
				var FileExt = file.name.replace(/.+\./, "");
				if (['jpg', 'png', 'zip', 'rar', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'mp4', 'mp3'].indexOf(
						FileExt
						.toLowerCase()) === -
					1) {
					this.$message({
						type: 'error',
						message: '上传失败，请上传后缀名为jpg、png、pdf、doc、docx、xlsx、xls、mp4、mp3、zip或rar的附件！'
					});
					return false;
				}
				if ((file.size / 1024 / 1024) > 700) {
					this.$message({
						message: '上传失败，上传文件大小不能超过600M',
						type: 'error'
					});
					return false;
				}
			},
			//作品文件上传成功回调
			successFile(res) {
				this.$message.success('文件上传成功，请点击提交作品');
				this.formState.file = res.data.url;
				this.file_list = [{
					'name': "文件已上传",
					"url": this.formState.file
				}]
			},
			errorFile() {
				this.$message.error('当前网络异常，请检查网络，重新上传');
			},
			//文件超出个数限制时的钩子
			exceedFile() {
				this.$message.warning('超出1个文件，请先删除当前文件，再重新上传');
			},
			//删除作品
			removeFile() {
				this.formState.file = '';
			},
			//文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
			changeFile(files, fileList) {
				if (fileList.length > 0) {
					this.file_list = [files];
				}
			},
			//关闭抽屉
			drawerCloseFN() {
				this.visible = false;
				this.formState = this.$options.data().formState;
				this.formState_edit_index = -1;
				this.file_list = [];
			},
			//打开抽屉
			drawerOpenFN() {
				if (this.user_info.u_type != 1 && this.showBatchData == false) {
					this.showBatchData = true;
				}
				this.$forceUpdate();
				this.visible = true;
			},
			//获取详情
			getActivityItemFN() {
				const params = {
					id: this.id
				}
				this.$api.activity.getItem(params).then((res) => {
					// 处理相应的业务
					this.info = res.data.list[0];
					this.getActivityUserItemFN();
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//查询是否参加
			getActivityUserItemFN() {
				const params = {
					activity_id: this.id
				}
				this.$api.auth.activity_user.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.code == 402) {
						this.$router.push({
							path: '/Center',
						})
						return;
					}
					if (res.data.list.length > 0) {
						this.activity_user = res.data.list[0];
					} else {
						this.getUserInfo();
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//提交数据
			postCheckFN() {
				if (!this.formState.username) {
					this.$message.info('请输入姓名');
					return;
				}
				if (!this.formState.phone) {
					this.$message.info('请输入手机号');
					return;
				}
				if (!this.formState.idcode) {
					this.$message.info('请输入身份证号码');
					return;
				}
				if (!this.formState.sex) {
					this.$message.info('请选择性别');
					return;
				}
				const params = {
					activity_id: this.id,
					username: this.formState.username,
					phone: this.formState.phone,
					idcode: this.formState.idcode,
					sex: this.formState.sex,
					file: this.formState.file,
				}
				if (this.user_info.u_type == 1) { //学生
					this.postFN(params);
				} else {
					if (this.formState_edit_index >= 0) { //编辑
						this.batchUser[Number(this.formState_edit_index)] = params;
					} else {
						this.batchUser.push(params);
					}
					this.drawerCloseFN();
				}
				//重置
				this.formState_edit_index = -1;
				this.formState = this.$options.data().formState;
			},
			//学校、教育局-提交数据
			postManyFN() {
				if (this.batchUser.length == 0) {
					this.$message.info('请先添加报名成员信息');
					return;
				}
				if (!this.file_user) {
					this.$message.info('请上传比赛成员信息汇总表');
					return;
				}
				const params = {
					batchUser: JSON.stringify(this.batchUser),
					file_user: this.file_user,
					activity_id: this.id
				}
				this.$api.auth.activity_user.joinBatchActivity(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message.success(res.info);
						//关闭弹窗
						this.visible = false;
						this.showBatchData = false;
					} else {
						this.$message.info(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//学生类型-提交数据
			postFN(params) {
				this.$api.auth.activity_user.joinActivity(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message.success(res.info);
						//关闭弹窗
						this.visible = false;
						//刷新参加状态
						this.getActivityUserItemFN();
					} else {
						this.$message.info(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>