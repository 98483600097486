import {
	// http,
	baseUrl,
	post
} from './axios'

//基础数据接口
const data = {
	getData: (params = {}) => post('/api/data/getData', params),
	getBase: (params = {}) => post('/api/data/getBase', params),
}

//轮播数据接口
const swiper = {
	getItem: (params = {}) => post('/api/swiper/getItem', params),
}

//文章数据接口
const news = {
	getCate: (params = {}) => post('/api/news/getCate', params),
	getItem: (params = {}) => post('/api/news/getItem', params),
	getCateIndex: (params = {}) => post('/api/news/getCateIndex', params),
	getNewsTeamItem: (params = {}) => post('/api/news/getNewsTeamItem', params),
}


//演讲比赛分组数据接口
const group = {
	getItem: (params = {}) => post('/api/group/getItem', params),
}

//友情链接数据接口
const link = {
	getCate: (params = {}) => post('/api/link/getCate', params),
	getItem: (params = {}) => post('/api/link/getItem', params),
	getAll: (params = {}) => post('/api/link/getAll', params),
}

//法律知识数据接口
const law = {
	getCate: (params = {}) => post('/api/law/getCate', params),
	getItem: (params = {}) => post('/api/law/getItem', params),
}

//学习资料数据接口
const study = {
	getCate: (params = {}) => post('/api/study/getCate', params),
	getItem: (params = {}) => post('/api/study/getItem', params),
}

//演讲比赛数据接口
const activity = {
	getItem: (params = {}) => post('/api/activity/getItem', params),
}

//学校数据接口
const school = {
	getCate: (params = {}) => post('/api/school/getCate', params),
	findCateList: (params = {}) => post('/api/school/findCateList', params),
}

//所在地数据接口
const region = {
	getCate: (params = {}) => post('/api/region/getCate', params),
	findCateList: (params = {}) => post('/api/region/findCateList', params),
}

//注册数据接口
const login = {
	register: (params = {}) => post('/api/login/register', params),
	in: (params = {}) => post('/api/login/in', params),
	forgotPass: (params = {}) => post('/api/login/forgotPass', params),
	captcha: (params = {}) => post('/api/login/captcha', params),
}

//注册数据接口
const knowledge = {
	getItem: (params = {}) => post('/api/knowledge/getItem', params),
}

//注册数据接口
const auth = {
	knowledge: {
		getQuestion: (params = {}) => post('/api/auth.knowledge/getQuestion', params),
		join: (params = {}) => post('/api/auth.knowledge/join', params),
		getJoinStatus: (params = {}) => post('/api/auth.knowledge/getJoinStatus', params),
		submit: (params = {}) => post('/api/auth.knowledge/submit', params),
		getList: (params = {}) => post('/api/auth.knowledge/getList', params),
		getScoreDetail: (params = {}) => post('/api/auth.knowledge/getScoreDetail', params),
	},
	center: {
		set: (params = {}) => post('/api/auth.center/set', params),
		get: (params = {}) => post('/api/auth.center/get', params),
		upload: (params = {}) => post('/api/auth.center/upload', params),
	},
	//演讲比赛用户数据接口
	activity_user: {
		getItem: (params = {}) => post('/api/auth.ActivityUser/getItem', params),
		joinActivity: (params = {}) => post('/api/auth.ActivityUser/joinActivity', params),
		joinBatchActivity: (params = {}) => post('/api/auth.ActivityUser/joinBatchActivity', params),
		submitFile: (params = {}) => post('/api/auth.ActivityUser/submitFile', params),
	}

}


// 门户相关接口
const api = {
	baseUrl,
	data,
	swiper,
	news,
	group,
	link,
	law,
	study,
	activity,
	school,
	region,
	login,
	knowledge,
	auth,
}

export default api