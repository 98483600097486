<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item>学法活动</el-breadcrumb-item>
					<el-breadcrumb-item>法治素养竞赛</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br><br>
		<div class="main_w mar_auto">
			<div class="flex_jc">
				<div class="rectangle_icon bgcolor_main"></div>
				<div class="m-l-10 f-24 font_weight fa-s">{{info.name}}</div>
			</div>
			<div class="p-l-10 p-b-10 border_bottom color_A6A6A6 flex_jc m-t-13">

			</div>
			<br>
			<div>
				<div class="">
					<div v-if="join_status!=2" class="text_c">
						<el-row type="flex" justify="center">
							<el-col :span="3">
								<div class="">总题数：{{info.q_count}}</div>
							</el-col>
							<el-col :span="3">
								<div class="">满分：{{info.score}}</div>
							</el-col>
							<el-col :span="3">
								<div class="">合格分数：{{info.pass_score}}</div>
							</el-col>
						</el-row>
						<br>
						<!-- <div class="m-t-5">考试时间：{{info.test_date}}</div> -->
						<br>
						<div class="m-t-5">答题时间：{{info.test_min}}分钟</div>
						<br><br>
						<div v-if="join_status == 1">
							<el-button type="primary" style="width: 200px;" @click="open()">开始答题</el-button>
						</div>
						<div v-else>
							<el-button type="danger" style="width: 200px;" @click="toUserCenter()">查看我的知识竞赛</el-button>
						</div>
					</div>
					<div v-if="join_status == 2">
						<div class="text_c"><el-progress type="circle" :percentage="percentage"></el-progress></div>
						<div class="">
							<!-- 倒计时 -->
							<div class="m-t-10">
								<el-statistic ref="statistic" @finish="timeDoneFN" format="HH:mm:ss" :value="end_date"
									title="考试剩余时间" time-indices>
								</el-statistic>
							</div>
							<br>
							<!-- 试卷答题 -->
							<div v-for="(item,index) in question_list" :key="index">
								<div v-if="index == question_num_now">
									<br>
									<div class="font_weight font_s18">
										<span>{{index+1}}、{{item.name}}</span>
										<span class="color_A6A6A6" v-if="item.type_code==1">（单选题，{{item.score}}分）</span>
										<span class="color_A6A6A6" v-if="item.type_code==2">（多选题，{{item.score}}分）</span>
										<span class="color_A6A6A6" v-if="item.type_code==3">（判断题，{{item.score}}分）</span>
									</div>
									<div v-if="item.slider">
										<div v-for="(item2,index2) in item.slider.split('|')" :key="index2">
											<el-image style="width: 100px; height: 100px" :src="item2"
												:preview-src-list="item.slider.split('|')">
											</el-image>
										</div>
									</div>
									<div v-if="item.video">
										<video width="800" height="400" controls>
											<source :src="item.video" type="video/mp4">
											</source>
										</video>
									</div>
									<br>
									<el-radio-group v-model="item.answer" v-if="item.type_code==1||item.type_code==3">
										<div v-for="(item2,index2) in item.items" class="m-b-16" :key="index2">
											<el-radio border size="medium"
												:label="index2">{{abcd[index2]}}、{{item2.check_content}}</el-radio>
										</div>
									</el-radio-group>
									<el-checkbox-group v-model="item.answer" v-if="item.type_code==2">
										<div v-for="(item2,index2) in item.items" class="m-b-16" :key="index2">
											<el-checkbox border size="medium"
												:label="index2">{{abcd[index2]}}、{{item2.check_content}}</el-checkbox>
										</div>
									</el-checkbox-group>
								</div>
							</div>
							<br><br><br>
							<div class="text_c">
								<el-button style="width: 200px;" @click="beforeQFN()">上一题</el-button>
								<el-button type="success" style="width: 200px;" @click="nextQFN()"
									v-if="(question_num_now+1)==question_list.length">提交答卷</el-button>
								<el-button type="primary" style="width: 200px;" @click="nextQFN()"
									v-else>下一题</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br><br>
	</div>
</template>

<script>
	export default {
		name: 'MatchDetail',
		components: {},
		props: [''],
		data() {
			return {
				id: "",
				done_count: 0,
				percentage: 0,
				info: {},
				question_list: [],
				join_status: "",
				question_num_now: 0,
				end_date: "",
				abcd: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'],
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			if (!this.$route.query.id) {
				return;
			}
			this.id = this.$route.query.id;
			this.geKnowledgeFN();
			this.getJoinStatusFN();
		},
		methods: {
			//查看我的学法评测
			toUserCenter() {
				this.$router.push({
					path: 'center',
					query: {
						tab: 'userKnowledge'
					}
				})
			},
			//考试时间结束
			timeDoneFN() {
				this.submitFN();
				this.$confirm('考试倒计时结束，答题已自动提交！', '温馨提示', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {}).catch(() => {

				});
			},
			//下一题
			nextQFN() {
				if (this.question_list.length == 0) {
					this.$message('暂无试题，请联系管理员添加试题');
					return;
				}
				if (this.question_list[this.question_num_now].answer.length == 0) {
					this.$message('请先完成答题选择');
					return;
				}
				//检查是否确认交卷处理，只能在完成最后一题可以交卷，不能未完成交卷或提前交卷
				if ((this.question_num_now + 1) == this.question_list.length) {
					this.submitConFN();
					return;
				}
				this.question_num_now++;
				var percentage = (this.question_num_now / (this.info.q_count > 0 ? this.info.q_count : 1)).toFixed(
					2);
				percentage = Number(percentage) * 100;
				percentage = percentage.toFixed(0);
				this.percentage = Number(percentage);
			},
			//返回上一题
			beforeQFN() {
				if (this.question_num_now == 0) {
					return;
				}
				this.question_num_now--;
				var percentage = (this.question_num_now / (this.info.q_count > 0 ? this.info.q_count : 1)).toFixed(
					2);
				percentage = Number(percentage) * 100;
				percentage = percentage.toFixed(0);
				this.percentage = Number(percentage);
			},
			//打开确认参加答题消息框
			submitConFN() {
				this.$confirm('提交答卷后将结束答题，不能再次提交，您检查好了吗?', '温馨提示', {
					confirmButtonText: '确定交卷',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.submitFN();
				}).catch(() => {

				});
			},
			//提交试卷内容
			submitFN() {
				const params = {
					knowledge_id: this.id,
					test_content: JSON.stringify(this.question_list),
				}
				this.$api.auth.knowledge.submit(params).then((res) => {
					if (res.code == 1) {
						this.$refs.statistic.suspend(true); //暂停倒计时
						this.$message({
							showClose: true,
							message: res.info,
							type: 'success'
						});
						//跳转到成绩查看
						this.toUserCenter();
					} else {
						this.$message(res.info);
					}
					this.getJoinStatusFN();
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取文章详情
			geKnowledgeFN() {
				const params = {
					id: this.id
				}
				this.$api.knowledge.getItem(params).then((res) => {
					// 处理相应的业务
					this.info = res.data.list[0];
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取试卷内容
			getQuestionFN() {
				const params = {
					knowledge_id: this.id
				}
				this.$api.auth.knowledge.getQuestion(params).then((res) => {
					// 处理相应的业务
					var list = res.data.list;
					for (let i = 0; i < list.length; i++) {
						list[i].items = JSON.parse(list[i].items);
						list[i].answer = []; //兼容多选框，所以是数组初始化
					}
					this.question_list = list;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//打开确认参加答题消息框
			open() {
				this.$confirm('答题期间请保持当前页面不被关闭，且开始答题后不能中途暂停、退出！ 确认开始答题?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.join();
				}).catch(() => {

				});
			},
			//查询答题参加状态
			getJoinStatusFN() {
				const params = {
					knowledge_id: this.id
				}
				this.$api.auth.knowledge.getJoinStatus(params).then((res) => {
					if (res.code == 402) {
						this.$router.push({
							path: '/Center',
						})
						return;
					}
					if (res.data && res.data.join_status) {
						this.join_status = res.data.join_status;
					}
					if (res.code == 0 && this.join_status != 3) {
						this.$message(res.info);
					} else if (res.code == 2) {
						this.end_date = res.data.end_date; //必须是毫秒的时间戳
						this.getQuestionFN();
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//开始答题
			join() {
				const params = {
					knowledge_id: this.id
				}
				this.$api.auth.knowledge.join(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message({
							showClose: true,
							message: '参加成功，请开始答题！',
							type: 'success'
						});
						this.getJoinStatusFN();
						this.getQuestionFN();
					} else {
						this.$message(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>