import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/user/Register.vue'
import Login from '../views/user/Login.vue'
import Forgot from '../views/user/Forgot.vue'

import Center from '../views/user/Center.vue'

import HomeView from '../views/home/Index.vue'

import ConSpeed from '../views/constitution/Speed.vue'
import BaseAbout from '../views/base/About.vue'
import BaseLaw from '../views/base/Law.vue'
import BaseContact from '../views/base/Contact.vue'

import LawIndex from '../views/law/Index.vue'
import LawDetail from '../views/law/Detail.vue'

import NewsIndex from '../views/news/Index.vue'
import NewsDetail from '../views/news/Detail.vue'

import NewsTeamIndex from '../views/news-team/Index.vue'
import NewsTeamDetail from '../views/news-team/Detail.vue'

import StudyIndex from '../views/study/Index.vue'

import MatchKnowledgeIndex from '../views/match/knowledge/Index.vue'
import MatchKnowledgeDetail from '../views/match/knowledge/Detail.vue'

import MatchSpeechIndex from '../views/match/speech/Index.vue'
import MatchSpeechDetail from '../views/match/speech/Detail.vue'

Vue.use(VueRouter)

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [{
		path: '/', //跳转路径
		name: 'home',
		component: HomeView,
		mobile: "/h5/"
	},
	{
		path: '/center', //跳转路径
		name: 'Center',
		component: Center,
		mobile: "/h5/"
	},
	{
		path: '/forgot', //跳转路径
		name: 'Forgot',
		component: Forgot,
		mobile: "/h5/#/pages/user/forgot"
	},
	{
		path: '/register', //跳转路径
		name: 'Register',
		component: Register,
		mobile: "/h5/#/pages/user/register"
	},
	{
		path: '/login', //跳转路径
		name: 'Login',
		component: Login,
		mobile: "/h5/#/pages/user/login"
	},
	{
		path: '/speed', //跳转路径
		name: 'speed',
		component: ConSpeed,
		mobile: "/h5/#/pages/activity/index"
	},
	{
		path: '/base/contact', //跳转路径
		name: 'baseContact',
		component: BaseContact,
		mobile: "/h5/#/pages/base/contact"
	},
	{
		path: '/base/law', //跳转路径
		name: 'baseLaw',
		component: BaseLaw,
		mobile: "/h5/#/pages/base/law"
	},
	{
		path: '/base/about', //跳转路径
		name: 'baseAbout',
		component: BaseAbout,
		mobile: "/h5/#/pages/base/about"
	},
	{
		path: '/law', //跳转路径
		name: 'LawIndex',
		component: LawIndex,
		mobile: "/h5/#/pages/law/index"
	},
	{
		path: '/law_detail', //跳转路径
		name: 'LawDetail',
		component: LawDetail,
		mobile: "/h5/#/pages/law/detail"
	},
	{
		path: '/news', //跳转路径
		name: 'NewsIndex',
		component: NewsIndex,
		mobile: "/h5/#/pages/news/index"
	},
	{
		path: '/news_detail', //跳转路径
		name: 'NewsDetail',
		component: NewsDetail,
		mobile: "/h5/#/pages/news/detail"
	},
	{
		path: '/news-team/index', //跳转路径
		name: 'NewsTeamIndex',
		component: NewsTeamIndex,
		mobile: "/h5/#/pages/newsTeam/index"
	},
	{
		path: '/news-team/detail', //跳转路径
		name: 'NewsTeamDetail',
		component: NewsTeamDetail,
		mobile: "/h5/#/pages/newsTeam/detail"
	},
	{
		path: '/study', //跳转路径
		name: 'StudyIndex',
		component: StudyIndex,
		mobile: "/h5/#/pages/study/index"
	},
	{
		path: '/matchKnowledgeIndex', //跳转路径
		name: 'MatchKnowledgeIndex',
		component: MatchKnowledgeIndex,
		mobile: "/h5/#/pages/activity/index?active_tab=1"
	},
	{
		path: '/matchKnowledgeDetail', //跳转路径
		name: 'MatchKnowledgeDetail',
		component: MatchKnowledgeDetail,
		mobile: "/h5/#/pages/activity/index?active_tab=1"

	},
	{
		path: '/matchSpeechIndex', //跳转路径
		name: 'MatchSpeechIndex',
		component: MatchSpeechIndex,
		mobile: "/h5/#/pages/activity/index"
	},
	{
		path: '/matchSpeechDetail', //跳转路径
		name: 'MatchSpeechDetail',
		component: MatchSpeechDetail,
		mobile: "/h5/#/pages/activity/index"
	},
]

const router = new VueRouter({
	routes
})

export default router

// const router = createRouter({
// 	history: createWebHashHistory(),
// 	routes
// })