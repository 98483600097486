<template>
    <div class="main_w mar_auto">
		<br><br>
        <div class="flex_align">
            <div class="rectangle_icon bgcolor_main"></div>
            <div class="m-l-10 f-24 font_weight fa-s">演讲比赛结果公示</div>
        </div>
        <br>
        <div class="p-l-10 p-b-10 border_bottom color_888">比赛时间：2023年3月9日</div>
        <div>
            <!-- 内容 -->
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConSpeed',
    data() {
        return {

        };
    },

    computed: {},

    beforeMount() { },

    activated() { },

    methods: {},

    watch: {}

}

</script>
<style scoped></style>