<template>
	<div>
		<div style="min-height: 500px;">
			<div>
				<div v-for="(item,index) in list" :key="index">
					<div class="news_item">
						<div class="flex_between">
							<div class="flex_align">
								<div>
									<div class="com_ellipsis2 font_s18 font_weight">{{item.activity.name}}</div>
									<div class="m-t-10 font_s14 color_A6A6A6">报名时间：{{item.activity.join_date}}</div>
									<div class="font_s14 color_A6A6A6">参加时间：{{item.activity.activity_date}}</div>
								</div>
							</div>
							<div v-if="item.status==2">
								<el-button @click="showUpload(item)" type="danger"
									style="width: 200px;">重新上传作品</el-button>
							</div>
						</div>
						<!-- <el-divider></el-divider> -->
						<br>
						<div>
							<div class="com_ellipsis2 font_s18 font_weight">参赛人信息</div>
							<div class="m-t-10 font_s15 flex_align">
								<div class="label_width">报名-姓名：</div>
								<div>{{item.username}}</div>
							</div>
							<div class="m-t-10 font_s15 flex_align">
								<div class="label_width">报名-手机号码：</div>
								<div>{{item.phone}}</div>
							</div>
							<div class="m-t-10 font_s15 flex_align">
								<div class="label_width">报名-身份证号码：</div>
								<div>{{item.idcode}}</div>
							</div>
							<div class="m-t-10 font_s15 flex_align">
								<div class="label_width">报名-性别：</div>
								<div>{{item.sex}}</div>
							</div>
							<div class="m-t-10 font_s15 flex_align">
								<div class="label_width">报名-作品文件：</div>
								<a style="color: #4A90E2!important;" target="_blank" :href="item.file"
									:download="item.username+'-作品文件'" v-if="item.file">已上传</a>
							</div>
							<div v-if="item.review_show==1">
								<div class="m-t-10 flex_align font_s15">
									<div class="label_width">评分：</div>
									<div class="color_red">{{item.review_score||''}}</div>
								</div>
								<div class="m-t-10 flex_align font_s15">
									<div class="label_width">评委评价：</div>
									<div class="color_red">{{item.review_remark||''}}</div>
								</div>
							</div>
						</div>

					</div>
					<el-divider></el-divider>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
		<user-activity-detail ref="ukd"></user-activity-detail>
	</div>
</template>

<script>
	import UserActivityDetail from './UserActivityDetail.vue'
	export default {
		name: 'UserKnowledge',
		components: {
			UserActivityDetail
		},
		props: [''],
		data() {
			return {
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
					pagesCount: 0,
				},
			};
		},
		computed: {},
		beforeMount() {},
		mounted() {
			this.getList()
		},
		methods: {
			//打开上传弹窗
			showUpload(item) {
				this.$refs.ukd.activity_user = item;
				this.$refs.ukd.dialogVisible = true;
			},
			//切换分页
			changePageFN(page) {
				this.getList(page);
			},
			//获取文章列表
			getList(page) {
				const params = {
					page: page ? page : 1,
				}
				this.$api.auth.activity_user.getItem(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
					this.page.pagesCount = res.data.page.pages;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		/* border-bottom: 1px solid #EAEAEA; */
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		padding-right: 14px;
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
	}
</style>