<template>
	<div>
		<el-dialog :title="knowledge.name" :visible.sync="centerDialogVisible" width="70%" center>
			<div class="" style="padding:0 30px;">
				<div>
					<div class="text_c m-b-30">
						<div class="color_red" style="font-size: 80px;">{{info.score}}</div>
						<div>我的考试成绩</div>
					</div>
					<br>
					<div class="m-b-30" v-for="(item,index) in test_content" :key="index">
						<div>
							<div class="font_weight font_s18">
								<span>{{index+1}}、{{item.name}}</span>
								<span class="color_A6A6A6" v-if="item.type_code==1">（单选题，{{item.score}}分）</span>
								<span class="color_A6A6A6" v-if="item.type_code==2">（多选题，{{item.score}}分）</span>
								<span class="color_A6A6A6" v-if="item.type_code==3">（判断题，{{item.score}}分）</span>
							</div>
							<div class="m-b-16 m-t-10">
								<span>
									<span>
										<i class="el-icon-success color_259EE0" v-if="item.is_right==1"></i>
										<i class="el-icon-error color_red" v-else></i>
									</span>
									{{item.is_right==1?'正确':'错误'}}，正确答案：{{item.right_options}}，你的选择：
									<span v-if="item.type_code==2">
										<span v-for="(a,a_index) in item.answer" :key="a_index">
											{{abcd[a]}}
										</span>
									</span>
									<span v-else>{{abcd[item.answer]}}</span>
								</span>
							</div>
							<div v-if="item.type_code==1||item.type_code==3">
								<div v-for="(item2,index2) in item.items" :key="index2" :label="index2">
									<div :class="abcd[index2] == item.right_options?'color_259EE0':''">
										{{abcd[index2]}}、{{item2.check_content}}
									</div>

								</div>
							</div>
							<div v-if="item.type_code==2">
								<div v-for="(item2,index2) in item.items" :key="index2" :label="index2">
									<div
										:class="item.right_options.split(',').indexOf(abcd[index2])!=-1?'color_259EE0':''">
										{{abcd[index2]}}、{{item2.check_content}}
									</div>
								</div>
							</div>
							<br>
							<div v-if="item.slider" class="m-b-30">
								<div v-for="(item2,index2) in item.slider.split('|')" :key="index2">
									<el-image style="width: 100px; height: 100px" :src="item2"
										:preview-src-list="item.slider.split('|')">
									</el-image>
								</div>
							</div>
							<div v-if="item.video" class="m-b-30">
								<video width="800" height="400" controls>
									<source :src="item.video" type="video/mp4">
									</source>
								</video>
							</div>
							<div v-if="item.content" class="m-b-30">
								<div v-html="item.content"></div>
							</div>
						</div>
						<el-divider></el-divider>
					</div>
				</div>
			</div>
			<br>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 200px;" type="primary" round @click="centerDialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'UserKnowledgeDetail',
		components: {},
		props: [],
		data() {
			return {
				knowledge: {},
				centerDialogVisible: false,
				info: {},
				test_content: [],
				abcd: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'],
			};
		},
		computed: {},
		beforeMount() {},
		mounted() {

		},
		methods: {
			getScoreDetail(knowledge_user_id) {
				const params = {
					knowledge_user_id: knowledge_user_id
				}
				this.info = {};
				this.test_content = [];
				this.$api.auth.knowledge.getScoreDetail(params).then((res) => {
					// // 处理相应的业务
					if (res.code == 1) {
						this.info = res.data;
						this.test_content = res.data.test_content;
					} else {
						this.$message(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		border-bottom: 1px solid #EAEAEA;
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		padding-right: 14px;
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
	}

	>>>.el-radio.is-bordered.is-disabled {
		border-color: #409EFF !important;
	}

	>>>.el-radio__input.is-disabled+>>>span.el-radio__label {
		color: #409EFF !important;
	}
</style>