<template>
	<div id="app">
		<nav-header v-on:searchNav="searchApp"></nav-header>
		<keep-alive>
			<router-view ref="navBox" style="min-height: 77vh;" />
		</keep-alive>
		<nav-bottom></nav-bottom>
	</div>
</template>
<script>
	import router from './router'
	import NavHeader from './components/NavHeader.vue'
	import NavBottom from './components/NavBottom.vue'
	export default {
		name: 'App',
		components: {
			NavHeader,
			NavBottom
		},
		mounted() {
			if (this.isMobile()) {
				for (var i = 0; i < router.options.routes.length; i++) {
					var item = router.options.routes[i];
					if (item.name == router.app._route.name) {
						var url = ''
						if (router.app._route.query.id) {
							url = item.mobile + '?id=' + router.app._route.query.id
						} else {
							url = item.mobile;
						}
						console.log('https://' + window.location.host + url)
						window.location.replace('https://' + window.location.host + url);
					}
				}
				// 跳转至手机端路由

			} else {
				// 跳转至 pc 端路由
			}
		},
		methods: {
			//搜索传递
			searchApp(val) {
				if (typeof(this.$refs.navBox.keywords) != 'undefined') {
					this.$refs.navBox.keywords = val;
					this.$refs.navBox.searchListenFN();
				}
			},
			// 机型判断
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
		}
	}
</script>
<style lang="less">
	html,
	body {
		margin: 0;
		font-size: 16px;
		min-width: 1640px;
	}

	@import url('assets/css/base.less');
	@import url('assets/css/project.less');
</style>