export default {
	install(Vue) {
		//这里是示例方法 logout是方法名 function()可以携带参数
		Vue.prototype.logout = function() {
			localStorage.removeItem('u_token');
			this.$router.push({
				path: '/login',
				query: {}
			})
			location.reload();
		},
		//判断是否为手机号
		Vue.prototype.isPhone = function(tel) {
			if (tel === '' || tel === undefined) {
				return false;
			}
			let is = /^1[3-9]\d{9}$/.test(tel);
			if (!is) {
				return false;
			}
			return true;
		}
	}
}