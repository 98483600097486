<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">法律声明</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main_w mar_auto">
			<br>
			<div class="" v-html="info.content"></div>
			<br>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BaseLaw',
		components: {},
		props: [''],
		data() {
			return {
				info: {}
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			this.getDataFN('Law')
		},
		methods: {
			//获取关于我们
			getDataFN(name) {
				const params = {
					name: name
				}
				this.$api.data.getData(params).then((res) => {
					// 处理相应的业务
					this.info = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>