<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6" v-if="type_code==1">新闻动态</el-breadcrumb-item>
					<el-breadcrumb-item class="color_A6A6A6" v-if="type_code==2">首页</el-breadcrumb-item>
					<el-breadcrumb-item>文章列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br>
		<div class="main_w mar_auto" style="min-height: 600px;">
			<div v-if="list.length>0">
				<div v-for="(item,index) in list" :key="index">
					<router-link :to="'/news_detail?id='+item.id" target="_blank">
						<div class="news_item animate_img-scale">
							<div class="flex">
								<div class="flex_align">
									<div class="item_day color_A6A6A6">{{item.news_date.split('-')[2]}}</div>
									<div class="p-l-14">
										<div class="color_A6A6A6 com_nowrap">
											{{item.news_date.split('-')[0]}}年{{item.news_date.split('-')[1]}}月
										</div>
										<div class=""><img src="@/assets/img/time.png" style="width:12px;height:12px"
												alt=""></div>
									</div>
								</div>
								<div class="m-l-40">
									<div class="flex_align">
										<div class="m-r-30 com_overflow" style="flex-shrink: 0;" v-if="item.cover"><img
												class="nimg" :src="item.cover" alt="" style="width:200px;height:133px;">
										</div>
										<div>
											<div class="com_ellipsis2 font_s18 font_weight">{{item.name}}</div>
											<div class="com_ellipsis2 m-t-10">{{item.des}}</div>
											<br>
											<div class="">
												<img src="@/assets/img/right.png" style="width:25px;height:15px" alt="">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</router-link>
					<div class="m-b-30" v-if="index==0 && ad_info.cover">
						<img @click="adFN()" :src="ad_info.cover" style="width: 1200px;height: 140px;" alt="">
					</div>
				</div>
			</div>
			<div v-else>
				<img v-if="ad_info.cover" @click="adFN()" :src="ad_info.cover" style="width: 1200px;height: 140px;"
					alt="">
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c main_w mar_auto" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
	</div>
</template>

<script>
	export default {
		name: 'NewsIndex',
		components: {},
		props: [''],
		data() {
			return {
				keywords: "",
				type_code: 1, //1是新闻动态列表，2是首页文章列表
				cateids: "",
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
					pagesCount: 0,
				},
				ad_info: {
					cover: "",
				},
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			if (this.$route.query.keywords) {
				this.keywords = this.$route.query.keywords;
			}
			if (this.$route.query.type_code) {
				this.type_code = this.$route.query.type_code;
			}
			this.cateids = this.$route.query.cateids;
			this.list = [];
			this.getNewsItemFN()
			if (this.type_code == 1) { //新闻动态
				this.getSwiperFN();
			}
		},
		methods: {
			//触发搜索
			searchListenFN() {
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getNewsItemFN();
			},
			//点击横幅图
			adFN() {
				if (this.ad_info.link_url) {
					window.open(this.ad_info.link_url, '_blank');
				}
			},
			//切换分页
			changePageFN(page) {
				this.getNewsItemFN(page);
			},
			//获取文章列表
			getNewsItemFN(page) {
				const params = {
					type_code: this.type_code,
					cateids: this.cateids,
					page: page ? page : 1,
					name: this.keywords,
				}
				if (this.keywords) {
					delete params.type_code;
					delete params.cateids
				}
				this.$api.news.getItem(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
					this.page.pagesCount = res.data.page.pages;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取文章列表
			getSwiperFN() {
				const params = {
					type_code: 2,
				}
				this.$api.swiper.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.data.list.length == 0) {
						return;
					}
					this.ad_info = res.data.list[0];
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		border-bottom: 1px solid #EAEAEA;
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
		width: 80px;
	}
</style>