<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item>个人中心</el-breadcrumb-item>
					<el-breadcrumb-item>{{activeIndex=='userInfo'?'个人信息管理':'成绩管理'}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="" style="padding: 30px;">
			<div class="main_w mar_auto box-shadow bgcolor_w">
				<div v-if="status == 2">
					<el-result icon="warning" title="账号审核中" subTitle="等待审核结果，审核通过您可以正常登录！">
						<template slot="extra">
							<div class="max_w">
								<div class="flex_jc">
									<div class="line_bottom main_color com_pointer" @click="dialogVisible = true">退出登录
									</div>
								</div>
							</div>
						</template>
					</el-result>
				</div>
				<el-container v-if="status == 1" style="min-height: 700px;padding: 30px 0;">
					<el-aside class="com_position" width="200px" style="border-right:1px solid #eee;">
						<!-- 个人信息 -->
						<div class="text_c m-t-30 m-b-30">
							<div>
								<el-avatar :size="60" :src="info.headimg"></el-avatar>
							</div>
							<div class="m-t-5 font_weight font_s15">{{info.nickname}}</div>
						</div>
						<el-menu :default-active="activeIndex" @select="centerMenuSelectFN" active-text-color="#3065C4"
							style="border-right:none">
							<el-menu-item index="userInfo">
								<i class="el-icon-user"></i>
								个人信息管理</el-menu-item>
							<el-menu-item index="userKnowledge">
								<i class="el-icon-postcard"></i>
								我的法治素养竞赛</el-menu-item>
							<el-menu-item index="userActivity">
								<i class="el-icon-tickets"></i>
								我的演讲比赛</el-menu-item>
						</el-menu>
						<div class="max_w" style="position: absolute;bottom:30px;">
							<div class="flex_jc">
								<div class="line_bottom main_color com_pointer" @click="dialogVisible = true">退出登录</div>
							</div>
						</div>
					</el-aside>
					<el-main>
						<div>
							<!-- 个人信息 -->
							<user-info @updateUserInfo="reload" v-if="activeIndex==='userInfo'"></user-info>
							<!-- 我的法治素养竞赛 -->
							<user-knowledge v-if="activeIndex==='userKnowledge'"></user-knowledge>
							<!-- 我的演讲比赛 -->
							<user-activity v-if="activeIndex==='userActivity'"></user-activity>
						</div>
					</el-main>
				</el-container>
			</div>
		</div>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
			<span>确定要退出登录吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button round @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" round @click="logoutFN()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import UserInfo from '@/components/UserInfo.vue'
	import UserKnowledge from '@/components/UserKnowledge.vue'
	import UserActivity from '@/components/UserActivity.vue'
	export default {
		name: 'UserCenter',
		components: {
			UserInfo,
			UserKnowledge,
			UserActivity,
		},
		props: [''],
		data() {
			return {
				dialogVisible: false,
				activeIndex: "userInfo",
				info: {},
				status: 0,
			};
		},
		watch: {
			$route() {
				this.setCurrentRoute();
			}
		},
		created() {
			this.setCurrentRoute();
		},
		computed: {},
		beforeMount() {},
		activated() {
			this.getUserInfo();
		},
		methods: {
			//退出登录
			logoutFN() {
				this.dialogVisible = false;
				this.logout();
			},
			//导航栏菜单刷新后根据路径回显
			setCurrentRoute() {
				// var path = this.$route.path;
				var tab = this.$route.query.tab;
				if (!tab) {
					this.activeIndex = 'userInfo';
				} else {
					this.activeIndex = tab;
				}
			},
			//刷新数据
			reload() {
				this.getUserInfo();
			},
			//菜单选中回调
			centerMenuSelectFN(index) {
				this.$router.push({
					path: 'center',
					query: {
						tab: index
					}
				});
			},
			//获取个人信息
			getUserInfo() {
				const params = {

				}
				this.$api.auth.center.get(params).then((res) => {
					// 处理相应的业务
					if (res.code == 402) {
						this.status = 2;
						return;
					}
					if (res.code == 1) {
						this.status = 1;
						this.info = res.data;
					} else {
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
					// this.$forceUpdate();
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		}
	}
</script>
<style scoped>
</style>