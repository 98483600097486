<template>
	<div class="">
		<img style="height: 706px;position: absolute;top: 160px;z-index: -1;left: 0;right: 0;width: 100%;" src="@/assets/img/top_bg.png" alt="">
		<div class="text_c font_weight font_s24 p-30 main_color">学法在线平台</div>
		<div class="box-shadow mar_auto bgcolor_w radius6" style="width: 450px;padding: 30px 60px;">
			<div class="font_weight font_s20">忘记密码</div>
			<br>
			<el-row type="flex" justify="center">
				<el-col :span="24">
					<el-form :model="ruleForm" status-icon :rules="rules" label-position="top" ref="ruleForm"
						label-width="100px" class="demo-ruleForm">
						<el-form-item label="">
							<el-input prefix-icon="el-icon-user" v-model="ruleForm.account" maxlength="20"
								placeholder="请输入登录账户"></el-input>
						</el-form-item>
						<el-form-item label="" prop="pass">
							<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass" maxlength="20"
								placeholder="请输入新密码" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="" prop="checkPass">
							<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
								maxlength="20" placeholder="请再次输入新密码" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item label="">
							<el-input prefix-icon="el-icon-bank-card" v-model="ruleForm.idcode" maxlength="60"
								placeholder="请输入身份证号码"></el-input>
						</el-form-item>
						<el-form-item>
							<el-col :span="14">
								<el-form-item>
									<el-input prefix-icon="el-icon-picture-outline" type="text" placeholder="点击图片可更换验证码"
										v-model="ruleForm.verify" maxlength="4" auto-complete="false"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<div class="text_c">
									<img :src="imgUrl" style="height: 41px;" @click="resetImg" />
								</div>
							</el-col>
						</el-form-item>
						<br>
						<el-form-item class="text_c">
							<el-button type="primary" style="width: 300px;" round
								@click="submitForm('ruleForm')">确定提交</el-button>
						</el-form-item>
						<el-form-item class="text_c m-t-10">
							<router-link to="/login">
								<span class="main_color line_bottom">记起账号密码？点击前往登录></span>
							</router-link>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</div>
		<br><br>
	</div>
</template>
<script>
	export default {
		name: "UserForgot",
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				imgUrl: "",
				ruleForm: {
					uniqid: "",
					account: "",
					idcode: "",
					pass: '',
					checkPass: '',
				},
				rules: {
					pass: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
				}
			};
		},
		activated() {
			this.resetImg();
		},
		methods: {
			//刷新验证码
			resetImg() {
				this.$api.login.captcha({}).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.imgUrl = res.data.image;
						this.ruleForm.uniqid = res.data.uniqid;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			submitForm(formName) {
				if (!this.ruleForm.account) {
					this.$message({
						message: '账户名不能为空',
						type: 'error'
					});
					return;
				}
				if (!this.ruleForm.idcode) {
					this.$message({
						message: '身份证不能为空',
						type: 'error'
					});
					return;
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.registerFN();
					} else {
						return false;
					}
				});
			},
			//注册接口
			registerFN() {
				const params = {
					verify: this.ruleForm.verify,
					uniqid: this.ruleForm.uniqid,
					account: this.ruleForm.account,
					idcode: this.ruleForm.idcode,
					password: this.ruleForm.pass,
				}
				this.$api.login.forgotPass(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message({
							message: '设置成功',
							type: 'success'
						});
						localStorage.setItem('u_token', res.data.token.token);
						this.$router.push({
							path: '/',
						})
						location.reload();
					} else {
						this.resetImg();
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		}
	}
</script>