<template>
	<div class="com_position">
		<img class="" style="height: 706px;position: absolute;top: -41px;z-index: -1;left: 0;right: 0;width: 100%;"
			src="@/assets/img/top_bg.png" alt="">
		<div class="max_main mar_auto com_position" id="swiper">
			<!-- 	<img style="position: absolute;top: 0;height: 480px;width: 100%;"
				src="http://www.moe.gov.cn/images/index_moe_focus_shadow.png" alt=""> -->
			<el-carousel class="com_position" :interval="6000" :autoplay="true" height="480px" arrow="hover"
				type="card">
				<el-carousel-item class="com_overflow radius6" v-for="(item,index) in swiper_list" :key="index">
					<div class="com_position">
						<el-image class="" @click="swiperClick(item)" :src="item.cover"
							style="width: 100%;height: 480px;display:block;"></el-image>
						<div class="color_w p-10 com_ellipsis"
							style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;">
							{{item.name}}
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 文章数据 -->
		<div>
			<div class="mar_auto main_w">
				<!-- 图片 -->
				<el-row :gutter="18" class="m-b-10">
					<el-col class="" :span="16">
						<div class="" style="width: 793px;">
							<div class="animate_img-scale com_overflow com_position radius6 com_pointer box-shadow">
								<el-image class="nimg" @click="openAdFN(PcAd.cover1_link)" :src="PcAd.cover1_pc"
									style="height: 400px;width: 100%;display:block;" alt=""></el-image>
								<div class="color_w p-10 com_ellipsis"
									style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;">
									{{PcAd.cover1_name}}
								</div>
							</div>
							<div class="flex_between m-t-10">
								<div class="animate_img-scale com_overflow com_position radius6 com_pointer box-shadow">
									<el-image class="nimg" @click="openAdFN(PcAd.cover2_link)"
										:src="PcAd.cover2_pc" style="height: 120px;width: 257px;display:block;" alt=""></el-image>
									<div class="color_w font_s13 com_ellipsis com_none"
										style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;padding: 5px 10px;">
										{{PcAd.cover2_name}}
									</div>
								</div>
								<div class="animate_img-scale com_overflow com_position radius6 com_pointer box-shadow">
									<el-image class="nimg" @click="openAdFN(PcAd.cover3_link)"
										:src="PcAd.cover3_pc" style="height: 120px;width: 257px;display:block;" alt=""></el-image>
									<div class="color_w font_s13 com_ellipsis com_none"
										style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;padding: 5px 10px;">
										{{PcAd.cover3_name}}
									</div>
								</div>
								<div class="animate_img-scale com_overflow com_position radius6 com_pointer box-shadow">
									<el-image class="nimg" @click="openAdFN(PcAd.cover4_link)"
										:src="PcAd.cover4_pc" style="height: 120px;width: 257px;display:block;" alt=""></el-image>
									<div class="color_w font_s13 com_ellipsis com_none"
										style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;padding: 5px 10px;">
										{{PcAd.cover4_name}}
									</div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col class="" :span="8" v-for="(item,index) in cates_list" :key="index">
						<div class="bgcolor_w news_card m-b-20" v-if="index==0">
							<div class="">
								<div class="flex_between com_position max_h p-l-10 p-r-10" style="height: 60px;">
									<img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 0;"
										src="@/assets/img/news_bg.png" alt="">
									<div class="flex_align com_position">
										<div class="font_s24 font_weight p-l-10" style="color: #082986;">{{item.name}}
										</div>
									</div>
									<router-link class="com_position font_s14"
										:to="'/news?type_code=2&cateids='+item.id" target="_blank"><span
											class="color_A6A6A6" href="#">更多&nbsp;>></span></router-link>
								</div>
								<div class="p-l-16">
									<div v-for="(news,news_index) in item.news_list" :key="news_index">
										<router-link :to="'/news_detail?id='+news.id" target="_blank">
											<div class="flex_between m-t-16 m-b-16 news_line">
												<div class="com_ellipsis font_s18">{{news.name}}</div>
												<!-- <div class="color_A6A6A6 com_nowrap">{{news.news_date}}</div> -->
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="18">
					<el-col class="m-b-20" :span="8" v-for="(item,index) in news" :key="index">
						<div class="bgcolor_w news_card">
							<div class="">
								<div class="flex_between com_position max_h p-l-10 p-r-10" style="height: 60px;">
									<img style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 0;"
										src="@/assets/img/news_bg.png" alt="">
									<div class="flex_align com_position">
										<div class="font_s24 font_weight p-l-10" style="color: #082986;">{{item.name}}
										</div>
									</div>
									<router-link class="com_position font_s14"
										:to="'/news?type_code=2&cateids='+item.id" target="_blank"><span
											class="color_A6A6A6" href="#">更多&nbsp;>></span></router-link>
								</div>
								<div class="p-l-16">
									<div v-for="(news,news_index) in item.news_list" :key="news_index">
										<router-link :to="'/news_detail?id='+news.id" target="_blank">
											<div class="flex_between m-t-16 m-b-16 news_line">
												<div class="com_ellipsis font_s18">{{news.name}}</div>
												<!-- <div class="color_A6A6A6 com_nowrap">{{news.news_date}}</div> -->
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 法律知识视频 -->
		<div class="">
			<div class="main_w mar_auto m-t-10">
				<el-row :gutter="6" style="margin-left: 0px;margin-right: 0;">
					<el-col :span="12">
						<div v-for="(item,index) in law_list" :key="index">
							<div v-if="index == 0">
								<router-link :to="'/law_detail?id='+item.id" target="_blank">
									<div class="box-shadow m-b-20 animate_img-scale com_overflow radius6"
										style="6px 5px 17px 0px rgb(0 0 0 / 24%)">
										<div class="">
											<div class="com_position">
												<div class="flex_jc" v-if="item.cover">
													<img class="radius6 nimg" :src="item.cover" alt=""
														style="width: 100%;height:380px;">
													<img class=""
														style="width: 50px;height: 50px;position: absolute;z-index: 2;"
														src="@/assets/img/bofang@2x.png" mode="" />
												</div>
												<div class="color_w p-10 com_ellipsis com_none"
													style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;padding: 5px 10px;">
													{{item.name}}
												</div>
											</div>
										</div>
									</div>
								</router-link>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<el-row :gutter="10" style="margin-left: 0px;margin-right: 0;">
							<el-col :span="12" v-for="(item,index) in law_list" :key="index">
								<div v-if="index>0&&index<=4">
									<div>
										<router-link :to="'/law_detail?id='+item.id" target="_blank">
											<div class="box-shadow m-b-10 animate_img-scale com_overflow radius6"
												style="6px 5px 17px 0px rgb(0 0 0 / 24%)">
												<div class="">
													<div class="com_position">
														<div class="flex_jc com_position" v-if="item.cover">
															<img class="radius6 nimg" :src="item.cover" alt=""
																style="width:100%;height:184px;">
															<img class=""
																style="width: 50px;height: 50px;position: absolute;z-index: 2;"
																src="@/assets/img/bofang@2x.png" mode="" />
														</div>
														<div class="color_w font_s13 com_ellipsis com_none"
															style="background-color: rgb(25 25 25 / 50%);position: absolute;bottom: 0;width: 100%;padding: 5px 10px;">
															{{item.name}}
														</div>
													</div>
												</div>
											</div>
										</router-link>
									</div>
								</div>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</div>
		<br>
		<!-- 横幅图 -->
		<!-- <div>
			<div class="ad_cover">
				<div class="mar_auto text_c main_w">
					<el-image class="max_w" :src="require('@/assets/img/2.png')" style="height: 140px;width: 100%;"
						alt=""></el-image>
				</div>
			</div>
		</div>
		<br> -->
		<!-- 导航图 -->
		<div class="fixed_left com_none">
			<div class="text_r color_A6A6A6 font_s12 com_pointer" @click="fixed_left = false" v-show="fixed_left">关闭
			</div>
			<div class="m-t-10">
				<transition name="el-zoom-in-top">
					<div v-show="fixed_left" class="transition-box">
						<el-image class="max_w" :src="require('@/assets/img/code_wx_company.jpg')"
							style="height: 100px;width: 100px;display: block;" alt=""></el-image>
					</div>
				</transition>
				<transition name="el-zoom-in-bottom">
					<div v-show="fixed_left" class="transition-box m-t-10">
						<el-image class="max_w" :src="require('@/assets/img/code_wx_xfh.jpg')"
							style="height: 100px;width: 100px;display: block;" alt=""></el-image>
					</div>
				</transition>
			</div>
		</div>
		<div class="fixed_right com_none">
			<div class="text_r color_A6A6A6 font_s12 com_pointer" @click="fixed_right = false" v-show="fixed_right">关闭
			</div>
			<div class="m-t-10">
				<transition name="el-zoom-in-center">
					<div v-show="fixed_right" class="transition-box">
						<el-image class="max_w" :src="require('@/assets/img/code_xcx_xfh.jpg')"
							style="height: 100px;width: 100px;display: block;" alt=""></el-image>
					</div>
				</transition>
				<transition name="el-zoom-in-top">
					<div v-show="fixed_right" class="transition-box m-t-10">
						<el-image class="max_w" :src="require('@/assets/img/code_xcx_xfy.jpg')"
							style="height: 100px;width: 100px;display: block;" alt=""></el-image>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeIndex',
		props: [''],
		components: {},
		data() {
			return {
				fixed_left: true,
				fixed_right: true,
				keywords: "",
				swiper_list: [],
				cates_list: [],
				law_list: [],
				PcAd: {},
			};
		},

		computed: {
			news: function() {
				return this.cates_list.filter(function(item, index) {
					return index > 0; //返回isShow=true的项，添加到activeUsers数组
				})
			},
		},

		beforeMount() {},

		activated() {
			this.getCateIndexFN();
			this.getLawItemFN();
			this.getDataFN('PcAd');
		},
		mounted() {
			this.getSwiperFN();
		},
		methods: {
			//触发搜索
			searchListenFN() {
				// Object.assign(this.page, this.$options.data().page); //重置
				this.cates_list = [];
				this.getCateIndexFN();
			},
			openAdFN(link) {
				if (link) {
					window.open(link);
				}
			},
			swiperClick(item) {
				if (!item.link_url) {
					return;
				}
				window.open(item.link_url);
			},
			//获取轮播图
			getSwiperFN() {
				const params = {
					type_code: 1
				}
				this.$api.swiper.getItem(params).then((res) => {
					// 处理相应的业务
					this.swiper_list = res.data.list;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取文章分类
			getCateIndexFN() {
				const params = {
					name: this.keywords,
				}
				this.$api.news.getCateIndex(params).then((res) => {
					// 处理相应的业务
					this.cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取法律知识列表-5
			getLawItemFN() {
				const params = {
					type_code: 2,
					page: 1,
					is_index: 1,
				}
				this.$api.law.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.law_list = res.data.list;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取推广图链接
			getDataFN(name) {
				this.$api.data.getData({
					name: name
				}).then(res => {
					if (res.code == 1) {
						this.PcAd = res.data;
					}
				});
			},
		},

		watch: {}

	}
</script>
<style scoped>
	/* 轮播图 */
	/* 	.ant-carousel :deep(.slick-slide) {
		text-align: center;
		height: 500px;
		line-height: 500px;
		background: #364d79;
		overflow: hidden;
	} */

	.news_card {
		height: 530px;
		box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		line-height: 28px;
	}

	.ad_cover {}

	.fixed_left {
		z-index: 11;
		position: fixed;
		top: 220px;
		/* bottom: 8%; */
		right: 50%;
		margin-right: 640px
	}

	.fixed_right {
		z-index: 11;
		position: fixed;
		top: 220px;
		/* bottom: 8%; */
		left: 50%;
		margin-left: 640px;
	}

	>>>.el-carousel__item.is-active {
		width: 1000px;
		transform: translateX(100px) !important;
	}

	>>>.el-carousel__arrow--left {
		left: 0;
	}

	>>>.el-carousel__arrow--right {
		right: 0;
	}

	>>>.el-carousel__arrow {
		width: 73px;
		height: 111px;
		background: rgb(49 49 49 / 78%);
		border-radius: 6px;
		font-size: 65px;
		color: #c6c6c6;
	}

	#swiper /deep/.el-carousel__mask {
		z-index: 1;
		opacity: .72;
	}
</style>