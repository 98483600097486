<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">专家队伍</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br><br>
		<div class="main_w mar_auto">
			<div class="flex_jc">
				<div class="rectangle_icon bgcolor_main"></div>
				<div class="m-l-10 f-24 font_weight fa-s">{{info.name}}</div>
			</div>
			<div class="p-l-10 p-b-10 border_bottom color_A6A6A6 flex_jc m-t-13">
				<img src="@/assets/img/time.png" style="width:10px;height:10px" alt="">
				<span>&nbsp;{{info.news_date}}</span>
			</div>
			<br><br>
			<div>
				<!-- 内容 -->
				<div class="" v-html="info.content"></div>
			</div>
		</div>
		<br><br>
	</div>
</template>

<script>
	export default {
		name: 'NewsTeamDetail',
		components: {},
		props: [''],
		data() {
			return {
				id: "",
				info: {}
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			if (!this.$route.query.id) {
				return;
			}
			this.id = this.$route.query.id;
			this.getNewsItemFN()
		},
		methods: {
			//获取文章详情
			getNewsItemFN() {
				const params = {
					id: this.id
				}
				this.$api.news.getNewsTeamItem(params).then((res) => {
					// 处理相应的业务
					this.info = res.data.list[0];
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>