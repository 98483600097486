<template>
	<div>
		<div style="min-height: 500px;">
			<div>
				<div v-for="(item,index) in list" :key="index">
					<div class="news_item">
						<div class="flex_between">
							<div class="flex_align">
								<div class="m-r-30" v-if="item.knowledge.cover"><img :src="item.knowledge.cover" alt=""
										style="width:200px;height:133px"></div>
								<div>
									<div class="com_ellipsis2 font_s18 font_weight">{{item.knowledge.name}}</div>
									<div class="com_ellipsis2 m-t-10">{{item.knowledge.des}}</div>
									<div class="m-t-10">考试时间：{{item.knowledge.test_date}}</div>
									<div class="m-t-10">答题时间：{{item.knowledge.test_min}}分钟</div>
									<!-- <div class="">
										<img src="@/assets/img/right.png" style="width:25px;height:15px" alt="">
									</div> -->
								</div>
							</div>
							<div>
								<el-button type="primary" round style="width: 200px;"
									@click="toSeeDetail(item)">查看我的答题成绩</el-button>
							</div>
						</div>
						<el-divider></el-divider>
					</div>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
		<user-knowledge-detail ref="ukd"></user-knowledge-detail>
	</div>
</template>

<script>
	import UserKnowledgeDetail from './UserKnowledgeDetail.vue'
	export default {
		name: 'UserActivity',
		components: {
			UserKnowledgeDetail
		},
		props: [''],
		data() {
			return {
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
					pagesCount: 0,
				},
			};
		},
		computed: {},
		beforeMount() {},
		mounted() {
			this.getList()
		},
		methods: {
			//查看我的答题成绩
			toSeeDetail(item) {
				this.$refs.ukd.knowledge = item.knowledge;
				this.$refs.ukd.getScoreDetail(item.id);
				this.$refs.ukd.centerDialogVisible = true;
			},
			//切换分页
			changePageFN(page) {
				this.getList(page);
			},
			//获取文章列表
			getList(page) {
				const params = {
					page: page ? page : 1,
				}
				this.$api.auth.knowledge.getList(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
					this.page.pagesCount = res.data.page.pages;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		/* border-bottom: 1px solid #EAEAEA; */
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		padding-right: 14px;
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
	}
</style>