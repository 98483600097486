<template>
	<div>
		<el-dialog title="上传作品" :visible.sync="dialogVisible" width="40%">
			<br><br>
			<div class="text_c">
				<el-upload class="" :on-change="changeFile" :on-success="successFile" :on-remove="removeFile"
					:on-error="errorFile" :before-upload="beforeUpload" :on-exceed="exceedFile" drag
					:file-list="file_list" :action="action_url" :multiple="false"
					:headers="{'api-name':'web','api-token':u_token}">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						<div>将作品文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip color_A6A6A6" slot="tip">建议上传压缩包zip，大小不高于600M的文件</div>
					</div>
				</el-upload>
			</div>
			<br><br>
			<span slot="footer" class="dialog-footer">
				<el-button round @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" round @click="postFileFN()">提交作品</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'UserActivityDetail',
		components: {},
		props: [],
		data() {
			return {
				u_token: localStorage.getItem('u_token'),
				action_url: '',
				dialogVisible: false,
				activity_user: {
					id: "",
				},
				formState: {
					file: "",
				},
				file_list: [],
			};
		},
		computed: {},
		beforeMount() {},
		created() {
			this.action_url = this.$api.baseUrl + '/api/auth.center/upload';
		},
		mounted() {

		},
		methods: {
			beforeUpload(file) {
				var FileExt = file.name.replace(/.+\./, "");
				if (['jpg', 'png', 'zip', 'rar', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'mp4', 'mp3'].indexOf(
						FileExt
						.toLowerCase()) === -
					1) {
					this.$message({
						type: 'error',
						message: '上传失败，请上传后缀名为jpg、png、pdf、doc、docx、xlsx、xls、mp4、mp3、zip或rar的附件！'
					});
					return false;
				}
				if ((file.size / 1024 / 1024) > 700) {
					this.$message({
						message: '上传失败，上传文件大小不能超过600M',
						type: 'error'
					});
					return false;
				}
			},
			//作品文件上传成功回调
			successFile(res) {
				this.$message.success('文件上传成功，请点击提交作品');
				this.formState.file = res.data.url;
				this.file_list = [{
					'name': "文件已上传",
					"url": this.formState.file
				}]
			},
			errorFile() {
				this.$message.error('当前网络异常，请检查网络，重新上传');
			},
			//文件超出个数限制时的钩子
			exceedFile() {
				this.$message.warning('超出1个文件，请先删除当前文件，再重新上传');
			},
			//删除作品
			removeFile() {
				this.formState.file = '';
			},
			//文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
			changeFile(files, fileList) {
				if (fileList.length > 0) {
					this.file_list = [files];
				}
			},
			postFileFN() {
				if (!this.formState.file) {
					this.$message.info('请先上传作品');
					return;
				}
				const params = {
					activity_user_id: this.activity_user.id,
					file: this.formState.file
				}
				this.$api.auth.activity_user.submitFile(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						//关闭弹窗
						this.dialogVisible = false;
						this.$message.success(res.info);
						location.reload();
					} else {
						this.$message.info(res.info);
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>