<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">学习资料</el-breadcrumb-item>
					<el-breadcrumb-item>列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br>
		<div class="main_w mar_auto" style="min-height: 600px;">
			<div>
				<el-row :gutter="21" style="margin-left: 0px;margin-right: 0;" class="bgcolor_w">
					<el-col :span="8" class="" v-for="(item,index) in list" :key="index">
						<div class="news_item">
							<div class="">
								<div class="">
									<div class="">
										<div class="com_overflow" style="height:291px;flex-shrink: 0;">
											<el-image class="" :src="item.cover"
												style="height: 100%;width: 100%;display:block;object-fit: contain;"
												alt=""></el-image>
										</div>
										<div class="max_w m-t-10">
											<div class="com_ellipsis font_s18 font_weight com_pointer">
												{{item.name}}
											</div>
											<div class="com_ellipsis2 m-t-10 com_pointer">
												{{item.des}}
											</div>
											<br>
											<div class="flex_between max_w">
												<div class="study_date">
													<div class="study_day bgcolor_main color_w flex_jc">
														{{item.news_date.split('-')[1]}}月{{item.news_date.split('-')[2]}}日
													</div>
													<div class="study_year main_color flex_jc">
														{{item.news_date.split('-')[0]}}
													</div>
												</div>
												<a target="_blank" class="study_btn flex_jc com_pointer"
													v-if="item.file" :href="item.file" download="点击下载">
													<image src="@/assets/img/download.png"
														style="width:16px;height:12px" alt=""></image>
													<div class="color_259EE0">&nbsp;点击下载</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c main_w mar_auto" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
	</div>
</template>

<script>
	export default {
		name: 'StudyIndex',
		components: {},
		props: [''],
		data() {
			return {
				keywords: "",
				cateids: "",
				list: [],
				page: {
					num: 1,
					total: 0,
					pagesCount: 0,
				},
			};
		},
		watch: {
			$route() {
				this.cateids = this.$route.query.cateids; //获取传来的参数 
				Object.assign(this.page, this.$options.data().page); //重置
				this.getStudyItemFN(); //路由变化时就重新执行这个方法 更新传来的参数
			}
		},
		computed: {},
		beforeMount() {},
		activated() {
			this.cateids = this.$route.query.cateids;
			this.list = [];
			this.getStudyItemFN()
		},
		methods: {
			//触发搜索
			searchListenFN() {
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getStudyItemFN();
			},
			//切换分页
			changePageFN(page) {
				this.getStudyItemFN(page);
			},
			//获取文章列表
			getStudyItemFN(page) {
				const params = {
					cateids: this.cateids,
					page: page ? page : 1,
					name: this.keywords,
					limit: 6
				}
				this.$api.study.getItem(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
					this.page.pagesCount = res.data.page.pages;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		}
	}
</script>
<style scoped>
	.news_item {
		/* border-top: 1px solid #EAEAEA; */
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.study_day {
		width: 100px;
		height: 26px;
	}

	.study_year {
		background: #FFFFFF;
		border: 1px solid #EAEAEA;
		width: 100px;
		height: 26px;
	}
</style>