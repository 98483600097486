<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">法律知识</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br><br>
		<div class="main_w mar_auto">
			<div class="flex_jc">
				<div class="rectangle_icon bgcolor_main"></div>
				<div class="m-l-10 f-24 font_weight fa-s">{{info.name}}</div>
			</div>
			<div class="p-l-10 p-b-10 border_bottom color_A6A6A6 flex_jc m-t-13">
				<img src="@/assets/img/time.png" style="width:10px;height:10px" alt="">
				<span>&nbsp;{{info.news_date}}</span>
			</div>
			<br><br>
			<div>
				<div v-if="info.file" class="u-p-30 flex_jc">
					<a target="_blank" class="study_btn flex_jc com_pointer" :href="info.file" download="点击下载">
						<img src="@/assets/img/download.png" style="width:16px;height:12px" alt="">
						<div class="u-m-l-10 font_s12">&nbsp;查看文件</div>
					</a>
				</div>
				<div v-if="info.video" class="">
					<video :src="info.video" style="width: 100%;" controls="controls"  webkit-playsinline="true" x-webkit-airplay="true" playsinline="true"
						x5-video-player-type="h5" x5-video-orientation="h5" x5-video-player-fullscreen="true"
						preload="auto" autoplay="autoplay" x5-playsinline="true"></video>
				</div>
				<!-- 内容 -->
				<div class="" v-html="info.content"></div>
			</div>
		</div>
		<br><br>
	</div>
</template>

<script>
	export default {
		name: 'LawDetail',
		components: {},
		props: [''],
		data() {
			return {
				id: "",
				info: {}
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			if (!this.$route.query.id) {
				return;
			}
			this.id = this.$route.query.id;
			this.getLawItemFN()
		},
		methods: {
			//获取文章详情
			getLawItemFN() {
				const params = {
					id: this.id
				}
				this.$api.law.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.info = res.data.list[0];
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
</style>