<template>
	<div class="">
		<img style="height: 706px;position: absolute;top: 160px;z-index: -1;left: 0;right: 0;width: 100%;"
			src="@/assets/img/top_bg.png" alt="">
		<div class="text_c font_weight font_s24 p-30 main_color">学法在线平台</div>
		<div class="box-shadow mar_auto bgcolor_w radius6" style="width: 450px;padding: 30px 60px;">
			<div class="font_weight font_s20">注册</div>
			<div class="color_888 font_s14 m-t-10">带<span class="color_red p-10">*</span>号为注册必填项</div>
			<br>
			<el-row type="flex" justify="center">
				<el-col :span="24">
					<el-form :model="ruleForm" status-icon :rules="rules" label-position="right" ref="ruleForm"
						label-width="12px" class="demo-ruleForm">
						<el-form-item label="">
							<el-select v-model="ruleForm.u_type" @change="resetFN" placeholder="请选择用户注册类型"
								class="max_w">
								<template slot="prefix">
									<span style="padding-left: 5px;">
										<i class="el-icon-aim"></i>
									</span>
								</template>
								<el-option v-for="item in register_options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<div v-if="ruleForm.u_type==1">
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-user" v-model="ruleForm.username" maxlength="20"
									placeholder="姓名"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm.phone" maxlength="60"
									placeholder="手机号"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-bank-card" v-model="ruleForm.idcode" maxlength="80"
									placeholder="身份证号码"></el-input>
							</el-form-item>
							<el-form-item label=" " prop="pass" required="">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass"
									maxlength="20" placeholder="输入密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " prop="checkPass" required="">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
									maxlength="20" placeholder="确认密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="所在地"
									v-model="ruleForm.area" :options="region_cates_list"
									:props="{label:'name',children:'sub',value:'name'}" :filterable="true" />
							</el-form-item>
							<el-form-item label=" " required="" class="">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="单位"
									v-model="ruleForm.cateids" :options="cates_list"
									:props="{label:'name',children:'sub',value:'id'}" :filterable="true" />
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.job_name" maxlength="60"
									placeholder="专业"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.class" maxlength="60"
									placeholder="班级"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.stu_num" maxlength="60"
									placeholder="学号"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.base_hometown" maxlength="60"
									placeholder="籍贯"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-date-picker v-model="ruleForm.base_birthday" value-format="yyyy-MM-dd"
									format="yyyy-MM-dd" :clearable="false" type="date" placeholder="出生年月"
									style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</div>
						<div v-if="ruleForm.u_type==2">
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-user" v-model="ruleForm.account" maxlength="20"
									placeholder="用户名"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm.phone" maxlength="60"
									placeholder="手机号"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="pass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass"
									maxlength="20" placeholder="输入密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="checkPass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
									maxlength="20" placeholder="确认密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " class="is_required">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="单位"
									v-model="ruleForm.cateids" :options="cates_list"
									:props="{label:'name',children:'sub',value:'id'}" :filterable="true" />
							</el-form-item>
							<el-form-item label="">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="所在地"
									v-model="ruleForm.area" :options="region_cates_list"
									:props="{label:'name',children:'sub',value:'name'}" :filterable="true" />
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.company_name" maxlength="60"
									placeholder="所在单位"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.now_job" maxlength="60"
									placeholder="职务"></el-input>
							</el-form-item>
						</div>
						<div v-if="ruleForm.u_type==3">
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-user" v-model="ruleForm.account" maxlength="20"
									placeholder="用户名"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm.phone" maxlength="60"
									placeholder="手机号"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="pass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass"
									maxlength="20" placeholder="输入密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="checkPass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
									maxlength="20" placeholder="确认密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " class="is_required">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="单位"
									v-model="ruleForm.cateids" :options="cates_list"
									:props="{label:'name',children:'sub',value:'id'}" :filterable="true" />
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.company_name" maxlength="60"
									placeholder="所在单位全称"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.now_job" maxlength="60"
									placeholder="职务"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.base_hometown" maxlength="60"
									placeholder="籍贯"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.teach_subject" maxlength="60"
									placeholder="任教学科"></el-input>
							</el-form-item>
							<el-form-item label="">
								<el-date-picker v-model="ruleForm.base_birthday" value-format="yyyy-MM-dd"
									format="yyyy-MM-dd" :clearable="false" type="date" placeholder="出生年月"
									style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</div>
						<div v-if="ruleForm.u_type==4">
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-user" v-model="ruleForm.username" maxlength="20"
									placeholder="姓名"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-select v-model="ruleForm.base_sex" placeholder="性别" class="max_w">
									<!-- <template slot="prefix">
										<span style="padding-left: 5px;">
											<i class="el-icon-aim"></i>
										</span>
									</template> -->
									<el-option v-for="item in sex_options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-c-scale-to-original" v-model="ruleForm.nation"
									maxlength="20" placeholder="民族"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-date-picker type="date" placeholder="出生日期" v-model="ruleForm.base_birthday"
									style="width: 100%;"></el-date-picker>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-select v-model="ruleForm.max_edu" placeholder="最高学历" class="max_w">
									<template slot="prefix">
										<span style="padding-left: 5px;">
											<i class="el-icon-aim"></i>
										</span>
									</template>
									<el-option v-for="item in max_edu.list" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-select v-model="ruleForm.max_degree" placeholder="最高学位" class="max_w">
									<template slot="prefix">
										<span style="padding-left: 5px;">
											<i class="el-icon-aim"></i>
										</span>
									</template>
									<el-option v-for="item in max_degree.list" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.job_name" maxlength="60"
									placeholder="职业"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.now_job" maxlength="60"
									placeholder="职务"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-select v-model="ruleForm.political_outlook" placeholder="政治面貌" class="max_w">
									<template slot="prefix">
										<span style="padding-left: 5px;">
											<i class="el-icon-aim"></i>
										</span>
									</template>
									<el-option v-for="item in political_outlook.list" :key="item.value"
										:label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="所在市县（区）"
									v-model="ruleForm.area" :options="region_cates_list"
									:props="{label:'name',children:'sub',value:'name'}" :filterable="true" />
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-tickets" v-model="ruleForm.company_name" maxlength="60"
									placeholder="现工作单位"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-cascader prefix-icon="el-icon-lock" style="width: 100%;" placeholder="法治副校长任职单位"
									v-model="ruleForm.cateids" :options="cates_list"
									:props="{label:'name',children:'sub',value:'id'}" :filterable="true" />
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm.phone" maxlength="60"
									placeholder="手机号码"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-select v-model="ruleForm.new_or_old" placeholder="新任或续任" class="max_w">
									<template slot="prefix">
										<span style="padding-left: 5px;">
											<i class="el-icon-aim"></i>
										</span>
									</template>
									<el-option v-for="item in new_or_old.list" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<!-- <el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-bank-card" v-model="ruleForm.idcode" maxlength="80"
									placeholder="身份证号码"></el-input>
							</el-form-item> -->
							<el-form-item label=" " required="" prop="pass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass"
									maxlength="20" placeholder="输入密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="checkPass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
									maxlength="20" placeholder="确认密码" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<div v-if="ruleForm.u_type==5">
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-user" v-model="ruleForm.account" maxlength="20"
									placeholder="用户名"></el-input>
							</el-form-item>
							<el-form-item label=" " required="">
								<el-input prefix-icon="el-icon-mobile-phone" v-model="ruleForm.phone" maxlength="60"
									placeholder="手机号"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="pass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.pass"
									maxlength="20" placeholder="输入密码" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label=" " required="" prop="checkPass">
								<el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.checkPass"
									maxlength="20" placeholder="确认密码" autocomplete="off"></el-input>
							</el-form-item>
						</div>
						<el-form-item label=" " required="">
							<el-col :span="14">
								<el-form-item>
									<el-input prefix-icon="el-icon-picture-outline" type="text" placeholder="图形验证码"
										v-model="ruleForm.verify" maxlength="4" auto-complete="false"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<div class="text_c">
									<img :src="imgUrl" style="height: 41px;" @click="resetImg" />
								</div>
							</el-col>
						</el-form-item>
						<br>
						<el-form-item class="text_c">
							<el-button type="primary" style="width: 300px;" round
								@click="submitForm('ruleForm')">马上注册</el-button>
						</el-form-item>
						<el-form-item class="text_c m-t-10">
							<router-link to="/login">
								<span class="main_color line_bottom">已有账号？点击前往登录></span>
							</router-link>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</div>
		<br><br>
	</div>
</template>
<style>
	.is_required>.el-form-item__label:before {
		content: "*";
		color: #f56c6c;
		margin-right: 4px;
	}
</style>
<script>
	export default {
		name: "UserRegister",
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				imgUrl: "",
				region_cates_list: [], //所在地
				cates_list: [], //学校
				ruleForm: {
					uniqid: "",
					account: "",
					idcode: "",
					cateids: [],
					area: [],
					pass: '',
					checkPass: '',
					u_type: 1,
					edu_name: "",
					phone: "",
					username: "", //姓名
					base_sex: "",
					political_outlook: "",
					max_title: "", //最高职称
					max_edu: "", //最高学历
					max_degree: "", //最高学位
					nation: "", //民族
					company_name: "", //工作单位全称
					job_name: "", //职业专业
					now_job: "", //当前职务
					stu_num: "", //学号
					base_birthday: "", //出生年月
					base_hometown: "", //籍贯
					teach_subject: "", //任教学科
					class: "", //班级
					new_or_old: "", //信任或续任
				},
				rules: {
					pass: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
				},
				nation: { //民族
					show: false,
					list: []
				},
				sex_options: [{
					value: '男',
					label: '男'
				}, {
					value: '女',
					label: '女'
				}],
				register_options: [{
					value: 2,
					label: '教育厅/局管理员'
				}, {
					value: 3,
					label: '学校管理员'
				}, {
					value: 1,
					label: '在校学生'
				}, {
					value: 4,
					label: '中小学法治副校长'
				}, {
					value: 5,
					label: '城市管理志愿者'
				}],
				political_outlook: {
					list: [{
						label: "中共党员",
						value: "中共党员"
					}, {
						label: "中共预备党员",
						value: "中共预备党员"
					}, {
						label: "共青团员",
						value: "共青团员"
					}, {
						label: "民革党员",
						value: "民革党员"
					}, {
						label: "民盟盟员",
						value: "民盟盟员"
					}, {
						label: "民建会员",
						value: "民建会员"
					}, {
						label: "民进会员",
						value: "民进会员"
					}, {
						label: "农工党党员",
						value: "农工党党员"
					}, {
						label: "致公党党员",
						value: "致公党党员"
					}, {
						label: "九三学社社员",
						value: "九三学社社员"
					}, {
						label: "台盟盟员",
						value: "台盟盟员"
					}, {
						label: "无党派人士",
						value: "无党派人士"
					}, {
						label: "群众",
						value: "群众"
					}],
				},
				new_or_old: {
					list: [{
						label: "新任",
						value: "新任"
					}, {
						label: "续任",
						value: "续任"
					}],
				},
				max_edu: {
					list: [{
						label: "研究生",
						value: "研究生"
					}, {
						label: "本科",
						value: "本科"
					}, {
						label: "专科",
						value: "专科"
					}]
				},
				max_degree: {
					list: [{
						label: "博士",
						value: "博士"
					}, {
						label: "硕士",
						value: "硕士"
					}, {
						label: "学士",
						value: "学士"
					}, {
						label: "无",
						value: "无"
					}]
				},
			};
		},
		activated() {
			this.resetImg();
			this.getCateIndexFN();
			this.getAreaCateIndexFN();
		},
		methods: {
			//重置输入
			resetFN(e) {
				this.ruleForm = Object.assign({}, this.$options.data().ruleForm);
				this.ruleForm.u_type = e;
				this.$forceUpdate();
				this.resetImg();
			},
			//刷新验证码
			resetImg() {
				this.$api.login.captcha({}).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.imgUrl = res.data.image;
						this.ruleForm.uniqid = res.data.uniqid;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//检查输入
			checkSubmit(u_type) {
				var require_params = [{
					name: "用户名",
					key: "account",
					u_type: [2, 3, 5]
				}, {
					name: "姓名",
					key: "username",
					u_type: [1, 4]
				}, {
					name: "性别",
					key: "base_sex",
					u_type: [4]
				}, {
					name: "民族",
					key: "nation",
					u_type: [4]
				}, {
					name: "身份证号码",
					key: "idcode",
					u_type: [1]
				}, {
					name: "单位",
					key: "cateids",
					u_type: [1, 2, 3, 4]
				}, {
					name: "所在地",
					key: "area",
					u_type: [1, 4]
				}, {
					name: "手机号",
					key: "phone",
					u_type: [1, 2, 3, 4, 5]
				}, {
					name: "新任或续任",
					key: "new_or_old",
					u_type: [4]
				}, {
					name: "政治面貌",
					key: "political_outlook",
					u_type: [4]
				}, {
					name: "出生日期",
					key: "base_birthday",
					u_type: [4]
				}]
				if (this.ruleForm.username) {
					this.ruleForm.account = this.ruleForm.username;
				}
				for (var i = 0; i < require_params.length; i++) {
					if (require_params[i].u_type.indexOf(u_type) != -1) {
						if (typeof(this.ruleForm[require_params[i].key]) === 'object') {
							if (this.ruleForm[require_params[i].key].length == 0) {
								this.$message({
									message: '请完善' + require_params[i].name + '信息',
									type: 'error'
								});
								return false;
							}
						} else {
							if (typeof(this.ruleForm[require_params[i].key]) === 'string') {
								if (this.ruleForm[require_params[i].key] == '') {
									this.$message({
										message: '请完善' + require_params[i].name + '信息',
										type: 'error'
									});
									return false;
								}
							}
						}
					}
				}
				return true;
			},
			submitForm(formName) {
				if (!this.ruleForm.u_type) {
					this.$message({
						message: '请选择用户注册类型',
						type: 'error'
					});
					return;
				}
				var check_res = this.checkSubmit(this.ruleForm.u_type);
				if (!check_res) {
					return;
				}
				if (this.ruleForm.phone) {
					if (!this.isPhone(this.ruleForm.phone)) {
						this.$message({
							message: '手机号输入不正确',
							type: 'error'
						});
						return;
					}
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.registerFN();
					} else {
						return false;
					}
				});
			},
			//注册接口
			registerFN() {
				const params = {
					verify: this.ruleForm.verify,
					uniqid: this.ruleForm.uniqid,
					account: this.ruleForm.account,
					idcode: this.ruleForm.idcode,
					cateids: this.ruleForm.cateids.join(','),
					region_province: this.ruleForm.area[0],
					region_city: this.ruleForm.area[1],
					region_area: this.ruleForm.area[2] ? this.ruleForm.area[2] : this.ruleForm.area[1],
					password: this.ruleForm.pass,
					u_type: this.ruleForm.u_type,
					edu_name: this.ruleForm.edu_name,
					phone: this.ruleForm.phone,
					username: this.ruleForm.username,
					base_sex: this.ruleForm.base_sex,
					political_outlook: this.ruleForm.political_outlook,
					max_title: this.ruleForm.max_title, //最高职称
					max_edu: this.ruleForm.max_edu, //最高学历
					max_degree: this.ruleForm.max_degree, //最高学位
					nation: this.ruleForm.nation, //民族
					company_name: this.ruleForm.company_name, //单位全称
					job_name: this.ruleForm.job_name, //职业专业
					now_job: this.ruleForm.now_job, //当前职务
					stu_num: this.ruleForm.stu_num, //学号
					base_birthday: this.ruleForm.base_birthday, //出生年月
					base_hometown: this.ruleForm.base_hometown, //籍贯
					teach_subject: this.ruleForm.teach_subject, //任教学科
					class: this.ruleForm.class, //班级
					new_or_old: this.ruleForm.new_or_old, //新任或续任
				}
				this.$api.login.register(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.$message({
							message: '注册成功',
							type: 'success'
						});
						localStorage.setItem('u_token', res.data.token.token);
						this.$router.push({
							path: '/',
						})
						location.reload();
					} else {
						this.ruleForm.verify = '';
						this.resetImg();
						this.$message({
							message: res.info,
							type: 'error'
						});
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取学校数据
			getCateIndexFN() {
				const params = {}
				this.$api.school.getCate(params).then((res) => {
					// 处理相应的业务
					this.cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取所在地数据
			getAreaCateIndexFN() {
				const params = {}
				this.$api.region.getCate(params).then((res) => {
					// 处理相应的业务
					this.region_cates_list = res.data;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
			//获取民族数据
			getNationFN() {
				const params = {
					name: "民族"
				}
				this.$api.data.getBase(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.nation.list = res.data.list;
					} else {
						this.nation.list = [];
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		}
	}
</script>