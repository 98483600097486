<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">专家队伍</el-breadcrumb-item>
					<el-breadcrumb-item>列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br>
		<div class="main_w mar_auto" style="min-height: 600px;">
			<div v-if="list.length>0">
				<div v-for="(item,index) in list" :key="index">
					<router-link :to="'/news-team/detail?id='+item.id" target="_blank">
						<div class="news_item animate_img-scale">
							<div class="flex">
								<div class="flex_align">
									<div class="item_day color_A6A6A6">{{item.news_date.split('-')[2]}}</div>
									<div class="p-l-14">
										<div class="color_A6A6A6 com_nowrap">
											{{item.news_date.split('-')[0]}}年{{item.news_date.split('-')[1]}}月
										</div>
										<div class=""><img src="@/assets/img/time.png" style="width:12px;height:12px"
												alt=""></div>
									</div>
								</div>
								<div class="m-l-40">
									<div class="flex_align">
										<div class="m-r-30 com_overflow" style="flex-shrink: 0;" v-if="item.cover"><img
												class="nimg" :src="item.cover" alt="" style="width:200px;height:133px;">
										</div>
										<div>
											<div class="com_ellipsis2 font_s18 font_weight">{{item.name}}</div>
											<div class="com_ellipsis2 m-t-10">{{item.des}}</div>
											<br>
											<div class="">
												<img src="@/assets/img/right.png" style="width:25px;height:15px" alt="">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c main_w mar_auto" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num" 
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
	</div>
</template>

<script>
	export default {
		name: 'NewsTeamIndex',
		components: {},
		props: [''],
		data() {
			return {
				keywords: "",
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
					pagesCount: 0,
				},
			};
		},
		computed: {},
		beforeMount() {},
		activated() {
			this.list = [];
			this.getNewsTeamItem();
		},
		methods: {
			//触发搜索
			searchListenFN() {
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getNewsTeamItem();
			},
			//切换分页
			changePageFN(page) {
				this.getNewsTeamItem(page);
			},
			//获取专家队伍文章列表
			getNewsTeamItem(page) {
				const params = {
					page: page ? page : 1,
					name: this.keywords,
				}
				this.$api.news.getNewsTeamItem(params).then((res) => {
					// 处理相应的业务
					this.list = res.data.list;
					this.page.total = res.data.page.total;
					this.page.pagesCount = res.data.page.pages;
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style scoped>
	.news_item {
		border-bottom: 1px solid #EAEAEA;
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
		width: 80px;
	}

</style>