<template>
	<div>
		<div class="breadcrumb flex_jc">
			<div class="main_w">
				<el-breadcrumb>
					<el-breadcrumb-item class="color_A6A6A6">法律知识</el-breadcrumb-item>
					<el-breadcrumb-item>列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<br>
		<div class="main_w mar_auto" style="min-height: 600px;">
			<div class="flex_align com_pointer" style="border: 1px solid #EAEAEA;width: 405px;">
				<div class="type_item flex_jc" :class="type_code==1?'type_active':'bg_F7F7F7'" @click="typeFN(1)">文章展示
				</div>
				<div class="type_item flex_jc" :class="type_code==2?'type_active':'bg_F7F7F7'" @click="typeFN(2)">视频展示
				</div>
				<div class="type_item flex_jc" :class="type_code==3?'type_active':'bg_F7F7F7'" @click="typeFN(3)">漫画展示
				</div>
			</div>
			<br>
			<div v-if="list.length>0">
				<div v-if="type_code==1">
					<div v-for="(item,index) in list" :key="index">
						<router-link :to="'/law_detail?id='+item.id" target="_blank">
							<div class="news_item bgcolor_w animate_img">
								<div class="flex">
									<div class="flex_align">
										<div class="item_day color_A6A6A6">{{item.news_date.split('-')[2]}}</div>
										<div class="p-l-14">
											<div class="color_A6A6A6 com_nowrap">
												{{item.news_date.split('-')[0]}}年{{item.news_date.split('-')[1]}}月
											</div>
											<div class=""><img src="@/assets/img/time.png"
													style="width:12px;height:12px" alt=""></div>
										</div>
									</div>
									<div class="m-l-40">
										<div class="flex_align">
											<div class="m-r-30" style="flex-shrink: 0;" v-if="item.cover"><img
													class="aimg" :src="item.cover" alt=""
													style="width:200px;height:133px;"></div>
											<div class="">
												<div class="com_ellipsis2 font_s18 font_weight">{{item.name}}</div>
												<div class="com_ellipsis2 m-t-10">{{item.des}}</div>
												<br>
												<div class="">
													<img src="@/assets/img/right.png" style="width:25px;height:15px"
														alt="">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<div v-else>
					<el-row :gutter="21" style="margin-left: 0px;margin-right: 0;" class="bgcolor_w">
						<el-col :span="8" class="" v-for="(item,index) in list" :key="index">
							<router-link :to="'/law_detail?id='+item.id" target="_blank">
								<div class="box-shadow p-20 m-b-20 animate_item bgcolor_w radius6">
									<div class="">
										<div class="">
											<div class="flex_jc" v-if="item.cover">
												<img :src="item.cover" alt=""
													style="min-width:346px;height:230px;max-width: 100%;">
												<img class="" v-if="type_code == 2"
													style="width: 50px;height: 50px;position: absolute;z-index: 2;"
													src="@/assets/img/bofang@2x.png" mode="" />
											</div>
											<div style="height: 60px;">
												<div class="com_ellipsis font_s18 font_weight m-t-10">{{item.name}}
												</div>
												<div class="com_ellipsis m-t-10">{{item.des}}</div>
											</div>
										</div>
										<div class="flex_between border_top_EAEAEA m-t-20 p-t-20">
											<div class="flex_align">
												<div class="">
													<img src="@/assets/img/time.png" style="width:12px;height:12px"
														alt="">
												</div>
												<div class="color_A6A6A6 com_nowrap m-l-5">
													{{item.news_date}}
												</div>
											</div>
											<div class="flex_align">
												<div class="color_A6A6A6 com_nowrap m-r-10">查看详情</div>
												<img src="@/assets/img/right.png" style="width:25px;height:15px" alt="">
											</div>
										</div>
									</div>
								</div>
							</router-link>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- 暂无数据 -->
			<div v-if="list.length==0">
				<el-empty :image-size="200"></el-empty>
			</div>
		</div>
		<br><br>
		<!-- 分页 -->
		<div class="text_c main_w mar_auto" v-if="list.length>0">
			<el-pagination background @current-change="changePageFN" :current-page="page.num"
				:page-count="page.pagesCount"></el-pagination>
		</div>
		<br><br><br>
	</div>
</template>

<script>
	export default {
		name: 'LawIndex',
		components: {},
		props: [''],
		data() {
			return {
				keywords: "",
				type_code: 1, //1是文章，2是视频，3是漫画
				cateids: "",
				list: [],
				page: {
					num: 1,
					status: "loadmore",
					total: 0,
					pagesCount: 0,
				},
				ad_info: {
					cover: "",
				},
			};
		},
		watch: {
			$route() {
				var cateids = this.$route.query.cateids; //获取传来的参数 
				if (this.cateids && cateids) {
					Object.assign(this.page, this.$options.data().page); //重置
				}
				this.cateids = cateids;
				this.getLawItemFN(); //路由变化时就重新执行这个方法 更新传来的参数
			}
		},
		computed: {},
		beforeMount() {},
		activated() {
			this.cateids = this.$route.query.cateids;
			this.list = [];
			this.getLawItemFN()
		},
		methods: {
			//触发搜索
			searchListenFN() {
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getLawItemFN();
			},
			//切换类型
			typeFN(type_code) {
				this.type_code = type_code;
				Object.assign(this.page, this.$options.data().page); //重置
				this.list = [];
				this.getLawItemFN();
			},
			//切换分页
			changePageFN(page) {
				this.page.num = page;
				this.getLawItemFN();
			},
			//获取文章列表
			getLawItemFN() {
				const params = {
					type_code: this.type_code,
					cateids: this.cateids,
					page: this.page.num,
					name: this.keywords,
				}
				this.$api.law.getItem(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.list = res.data.list;
						this.page.total = res.data.page.total;
						this.page.pagesCount = res.data.page.pages;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
	}
</script>
<style scoped>
	.news_item {
		border-bottom: 1px solid #EAEAEA;
		padding-left: 94px;
		padding-right: 94px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.item_day {
		text-align: center;
		font-size: 40px;
		font-weight: 500;
		border-right: 1px solid #EAEAEA;
		height: 56px;
		width: 80px;
	}

	.type_item {
		width: 135px;
		height: 56px;
	}

	.type_active {
		border-top: 3px solid #3065C4;
		background-color: white;
	}
</style>