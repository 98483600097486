<template>
	<div class="">
		<el-footer style="padding: 0;">
			<!-- <div class="friend_link flex_jc p-t-8 p-b-8">
				<div class="main_w">
					<div class="flex">
						<div class="font_weight text_c m-r-40 p-t-8 com_nowrap">友情链接</div>
						<el-row :gutter="20" class="max_w">
							<el-col :span="4" v-for="(item,index) in list" :key="index">
								<div class="p-t-8 p-b-8"><a :href="item.content" class="the_link com_nowrap"
										target="_blank">{{item.name}}</a>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div> -->
			<div class="friend_link flex_jc p-t-16 p-b-16 font_s15">
				<div class="main_w">
					<div class="flex">
						<div class="text_c m-r-40 p-t-8 com_nowrap color_w">友情链接</div>
						<el-row :gutter="20" class="max_w">
							<el-col :span="4" v-for="(item,index) in list" :key="index">
								<div class="com_pointer m-b-10"
									style="border: 1px solid #1C4286;background-color: #1C4286;padding: 8px 16px;border-radius: 2px;">
									<el-dropdown placement="top" style="width: 100%;" trigger="click">
										<div class="flex_between color_w">
											<span class="com_nowrap font_s15">{{item.name}}</span>
											<i class="el-icon-arrow-down"></i>
										</div>
										<el-dropdown-menu slot="dropdown" style="overflow-y: scroll;max-height: 400px;">
											<el-dropdown-item disabled v-if="item.list.length==0">
												<span class="com_nowrap">敬请期待</span>
											</el-dropdown-item>
											<el-dropdown-item v-for="(link,link_index) in item.list" :key="link_index">
												<a :href="link.link" class="com_nowrap"
													target="_blank">{{link.name}}</a>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<div class="flex_jc copyright">
				<div class="flex_between main_w">
					<div>
						<div class="flex_align m-b-16 font_s15">
							<span class="com_inline m-r-40 com_position">
								<router-link to="/base/about" target="_blank">
									<span class="" style="color: white!important;">关于我们</span>
								</router-link>
								<span class="line"></span>
							</span>
							<span class="com_inline m-r-40 com_position">
								<router-link to="/base/contact" target="_blank">
									<span class="" style="color: white!important;">联系我们</span>
								</router-link>
								<span class="line"></span>
							</span>
							<span class="com_inline">
								<router-link to="/base/law" target="_blank">
									<span class="" style="color: white!important;">法律声明</span>
								</router-link>
							</span>
						</div>
						<div class="flex_align font_s13">
							<img src="@/assets/img/bottom_2.png" alt="">
							<span class="com_inline p-l-10">
								<a href="https://beian.mps.gov.cn/#/query/webSearch?code=44011202002589"
									target="_blank"><span class=""
										style="color: white!important;">粤公网安备44011202002589号</span></a></span>
							<span class="com_inline p-l-10">
								<a href="https://beian.miit.gov.cn/" target="_blank"><span class=""
										style="color: white!important;">粤ICP备2023059197号</span></a></span>
						</div>
					</div>
					<div class="flex_align font_s12 color_w">
						<div class="p-6 radius6 bgcolor_w text_c m-l-10" style="width: 90px;height: 90px;">
							<img src="@/assets/img/code_wx_xfh.jpg" class="max_h max_w" alt="">
							<div class="m-t-8">学法汇公众号</div>
						</div>
					<!-- 	<div class="p-6 radius6 bgcolor_w text_c m-l-10" style="width: 90px;height: 90px;">
							<img src="@/assets/img/code_wx_company.jpg" class="max_h max_w" alt="">
							<div class="m-t-8">广州广尚科技</div>
						</div> -->
						<div class="p-6 radius6 bgcolor_w text_c m-l-10" style="width: 90px;height: 90px;">
							<img src="@/assets/img/code_xcx_xfh.jpg" class="max_h max_w" alt="">
							<div class="m-t-8">学法汇小程序</div>
						</div>
						<div class="p-6 radius6 bgcolor_w text_c m-l-10" style="width: 90px;height: 90px;">
							<img src="@/assets/img/code_xcx_xfy.jpg" class="max_h max_w" alt="">
							<div class="m-t-8">学法云小程序</div>
						</div>
					</div>
				</div>
			</div>
			<div class="">
				<el-backtop target=""></el-backtop>
			</div>
		</el-footer>
	</div>
</template>

<script>
	export default {
		name: 'NavBottom',
		components: {},
		props: [''],
		data() {
			return {
				cateids: "",
				cates_list: [],
				list: [],
			};
		},
		computed: {},
		beforeMount() {},
		mounted() {
			// this.getBaseFN();
			this.getLinkAllFN();
		},
		methods: {
			//获取友情链接
			getBaseFN() {
				this.$api.data.getBase({
					name: '友情链接'
				}).then(res => {
					if (res.code == 1) {
						this.list = res.data.list;
					}
				});
			},
			//获取友情链接列表
			getLinkAllFN() {
				const params = {}
				this.$api.link.getAll(params).then((res) => {
					// 处理相应的业务
					if (res.code == 1) {
						this.list = res.data;
					}
				}).catch(() => {}).finally(() => {
					// 关闭loading
				})
			},
		},
		watch: {}
	}
</script>
<style>
	.el-backtop,
	.el-calendar-table td.is-today {
		color: #1C4286;
	}
</style>
<style scoped>
	.friend_link {
		background: #3065C4;
	}

	.copyright {
		color: #bbbbbb !important;
		/* padding: 13px; */
		height: 183px;
		background: #1C4286;
	}

	.the_link {
		color: #333333;
		border-bottom: 1px solid #333;
	}

	/deep/.el-dropdown-menu__item {
		min-width: 160px;
	}
	
	.line{
		position: absolute;
		right: -20px;
		top: 3px;
		width: 1px;
		height: 14px;
		background-color: white;
	}
</style>